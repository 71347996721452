import images from 'assets/images/images'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useWindowSize from 'hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import { openSidebar } from 'redux/features/general/generalSlice'
import { FaAngleRight, FaAngleDown } from 'react-icons/fa'
import { permissionTypes } from 'types/permissionTypes'

const SidebarTop = () => {
	let navigate = useNavigate()
	const { t } = useTranslation()
	const { width } = useWindowSize()
	const dispatch = useDispatch()
	const {permissions} = useSelector(state => state.configuration)
	const {currentUser} = permissions.configurations

	const [nestedMenu, setNestedMenu] = useState(false)
	const [nestedMenuSubstitute, setNestedMenuSubstitute] = useState(false)
		
	const sidebarLinks = [
		{
			id: 0,
			name: t('homePage'),
			url: '/',
		},
		{
			id: 1,
			name: t('incomingGifts'),
			url: '/incoming-gift',
			permission: currentUser?.permissions[permissionTypes.Gifts]
		},
		{
			id: 2,
			name: t('outgoingGifts'),
			url: '/outgoing-gift',
			permission: currentUser?.permissions[permissionTypes.Gifts]
		},
		{
			id: 3,
			name: t('hospitality'),
			url: '/company-hospitality',
			permission: currentUser?.permissions[permissionTypes.CompanyHospitalities]
		},
		{
			id: 4,
			name: t('drafts'),
			url: '/draft',
			permission: currentUser?.permissions[permissionTypes.Gifts]
		},
		
		{
			id: 5,
			name: t('adminPanel'),
			url: '/admin-panel',
			content: [
				{
					id: '5a',
					name: t('companies'),
					url: '/admin-panel/location-group',
					permission: currentUser?.permissions[permissionTypes.Companies]
				},
				{
					id: '5b',
					name: t('giftLimit'),
					url: '/admin-panel/gift-limit',
					permission: currentUser?.permissions[permissionTypes.CompanyGiftLimits]
				},
				{
					id: '5c',
					name: t('giftType'),
					url: '/admin-panel/gift-type',
					permission: currentUser?.permissions[permissionTypes.GiftTypes]
				},
				{
					id: '5d',
					name: t('purposeoftheGift'),
					url: '/admin-panel/gift-purpose',
					permission: currentUser?.permissions[permissionTypes.GiftPurposes]
				},
				{
					id: '5e',
					name: t('relatedPartyType'),
					url: '/admin-panel/related-partyType',
					permission: currentUser?.permissions[permissionTypes.GiftRelatedPartyTypes]
				},
				{
					id: '5f',
					name: t('reasonsforRefusal'),
					url: '/admin-panel/reasons-refusal',
					permission: currentUser?.permissions[permissionTypes.GiftRejectReasons]
				},
				{
					id: '6f',
					name: t('currency'),
					url: '/admin-panel/currency',
					permission: currentUser?.permissions[permissionTypes.Currencies]
				},
				{
					id: '7f',
					name: t('userManagement'),
					url: '/admin-panel/user-management',
					permission: currentUser?.permissions[permissionTypes.Users]
				},
				{
					id: '8f',
					name: t('roleManagement'),
					url: '/admin-panel/role-management',
					permission: currentUser?.permissions[permissionTypes.Roles]
				},
				{
					id: '9f',
					name: t('settings'),
					url: '/admin-panel/settings',
					permission: currentUser?.permissions[permissionTypes.Settings]
				},
				{
					id: '10f',
					name: t('mailTemplates'),
					url: '/admin-panel/mail-template',
					permission: currentUser?.permissions[permissionTypes.EmailTemplates]
				},
				{
					id: '11f',
					name: t('mailLogs'),
					url: '/admin-panel/mail-log',
					permission: currentUser?.permissions[permissionTypes.EmailTemplates]
				},
				{
					id: '12f',
					name: t('eventLogs'),
					url: '/admin-panel/event-log',
					permission: currentUser?.permissions[permissionTypes.EventLogs]
				},
				{
					id: '13f',
					name: t('auditLogs'),
					url: '/admin-panel/audit-log',
					permission: currentUser?.permissions[permissionTypes.AuditLogs]
				},
				{
					id: '14f',
					name: t('apiKey'),
					url: '/admin-panel/api-key',
					permission: currentUser?.permissions[permissionTypes.ApiKeys]
				},
			],
		},
		currentUser.isSubstitute && {  // burayı true yapmayı unutma ...
			id: 6,
			name: t('substitutePanel'),
			url: '/substitute-panel',
			content: [
				{
					id: '6a',
					name: t('incomingGifts'),
					url: '/substitute-panel/incoming-gift',
					permission: true
				},
				{
					id: '6b',
					name: t('outgoingGifts'),
					url: '/substitute-panel/outgoing-gift',
					permission: true
				},
				{
					id: '6c',
					name: t('hospitality'),
					url: '/substitute-panel/company-hospitality',
					permission: true
				},
			],
		},
		
	]

	const sidebarURLclick = (sidebar) => {
		if (sidebar == '/admin-panel') {
			setNestedMenu(true)
			setNestedMenuSubstitute(false)
			navigate('/admin-panel/location-group')
			return
		}else if(sidebar == '/substitute-panel'){
			setNestedMenuSubstitute(true)
			setNestedMenu(false)
			navigate('/substitute-panel/incoming-gift')
			return
		}else if (sidebar.split('/')[1] != 'admin-panel' && sidebar.split('/')[1] != 'substitute-panel') {
			setNestedMenu(false)
			setNestedMenuSubstitute(false)
		}
		navigate(sidebar)
		setTimeout(() => {
			width <= 772 && dispatch(openSidebar('sidebar'))
		}, 300)
	}

	useEffect(() => {
		if (window.location.pathname.split('/')[1] == 'admin-panel') {
			setNestedMenu(true)
			setNestedMenuSubstitute(false)
		}
		if(window.location.pathname.split('/')[1] == 'substitute-panel'){
			setNestedMenuSubstitute(true)
			setNestedMenu(false)
		}
	}, [])


	return (
		<div className='sidebar-top'>
			<div onClick={() => navigate('/')} className='sidebar-top-logo'>
				<img src={images.sidebarLogo} alt='' />
			</div>
			<ul className='sidebar-top-links'>
				{sidebarLinks.map((sidebar, i) => {
					const hasPermission = sidebar.permission == true || (!(sidebar?.content?.length > 0) || sidebar?.content?.some(c => c.permission != false))
					if(!hasPermission) {
						return
					}
					const urlParts = window.location.pathname.split('/')[2]
					const isActive =
						sidebar.url === window.location.pathname ||
						`${sidebar.url}/add` === window.location.pathname ||
						(sidebar.url === '/' + window.location.pathname.split('/')[1] && urlParts != undefined)

					const activeClass = isActive ? 'active' : ''
					return (
						<>
							<li onClick={() => sidebarURLclick(sidebar.url)} key={i} className={`cabin-semibold ${activeClass}`}>
								{sidebar.name}
								{sidebar.id == 5 ? nestedMenu && sidebar.id == 5 ? <FaAngleDown size={18} /> : <FaAngleRight size={18} /> : ''}
								{sidebar.id == 6 ? nestedMenuSubstitute && sidebar.id == 6 ? <FaAngleDown size={18} /> : <FaAngleRight size={18} /> : ''}
							</li>
							{sidebar?.content?.length > 0 && (nestedMenu || nestedMenuSubstitute) && sidebar.url == "/"+ window.location.pathname.split('/')[1] && (
								<ul className='sidebar-top-links-nestedUl'>
									{sidebar.content.filter(sideContent => sideContent.permission != false).map((side, index) => {
										let activeUrlSplit = window.location.pathname
										let isNestedActive = activeUrlSplit === side.url
										let nestedClass = isNestedActive ? 'activeNested' : ''
										return (
											<li className={`${nestedClass}`} onClick={() => sidebarURLclick(side.url)} key={index}>
												<div>
													<div className='point'> </div>
													{side.name}
												</div>
											</li>
										)
									})}
								</ul>
							)}
						</>
					)
				})}
			</ul>
		</div>
	)
}

export default SidebarTop
