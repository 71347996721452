import { createSlice } from "@reduxjs/toolkit"
import { initialState } from '../../../initialState';


  
  export const apiKeySlice = createSlice({
    name: 'apiKey',
    initialState,
    reducers: {
      getApiKey: (state, action) => {      
          state.adminPanel.apiKey = action.payload    
      },
      getApiKeyFilterParametersFunc: (state, action) => {
          state.adminPanel.apiKeyFilterParameters = action.payload   
      }
    },
  })
  
  export const { getApiKey, getApiKeyFilterParametersFunc } = apiKeySlice.actions
  
  export default apiKeySlice.reducer