export const initialState = {
    sidebarToggle: true,
    errorInput: false,
    modals: {
        modalToggle: false,
        modalStatus: null,
        modalId: null,
        editableData: null
    },
    // accordion id list...
    accordions: {
        // etikhat: false,
        politikalar: false,
        teknikdestek: false,
    },
    homePageCard: {
        gelenhediyeler: [],
        gidenhediyeler: [],
        sirketagirlama: [],
        onaydabekleyenler: [],
    },
    adminPanel: {
        apiKey: [],
        apiKeyFilterParameters: [],
        /** ---------------------- */
        giftType: [],
        giftTypeFilterParameters: null,
        /** ---------------------- */
        giftLimit: [],
        giftLimitFilterParameters: null,
        /** ---------------------- */
        giftPurpose: [],
        giftPurposeFilterParameters:null,
        /** ---------------------- */
        relationPartyType: [],
        relationPartyTypeFilterParameters:null,
        /** ---------------------- */
        reasonsForRefusal: [],
        reasonsForRefusalFilterParameters:null,
        /** ---------------------- */
        locationGroup: [],
        locationGroupFilterParameters: null,
        getLocationGroupSelectList: [],
        /**----------------------- */
        currencies: [],
        currencyFilterParameters: [], 
        /**------------------------- */
        userManagement: [],
        userManagementFilterParameters: [], 
        /**------------------------- */
        roleManagement: [],
        roleManagementFilterParameters: [], 
        /**------------------------- */
        mailTemplate: [],
        mailTemplateFilterParameters: [], 
        /**------------------------- */
        mailLog: [],
        mailLogFilterParameters: [], 
        /**------------------------- */
        eventLog: [],
        eventLogFilterParameters: [], 
         /**------------------------- */
         auditLog: [],
         auditLogFilterParameters: [], 
    },
    incomingGiftObj: {
        incomingGift: [],
        incomingGiftFilterParameters: [], 
    },
    outgoingGiftObj: {
        outgoingGift: [],
        outgoingGiftFilterParameters: [], 
    },
    companyHospitalityObj: {
        companyHospitality: [],
        companyHospitalityFilterParameters: [], 
    },
    draftObj: {
        draft: [],
        draftFilterParameters: [], 
    },
    permissions: {
        configurations: {},
        permissionList: []
    },
    notifications: {
        notificationList: [],
        notificationListFilterParameters: [], 
    },
}
