/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CompactTable } from '@table-library/react-table-library/compact'
import { PropTypes } from 'prop-types'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import images from 'assets/images/images'
import Input from '../Input'
import SelectDr from '../SelectDr'
import DatePicker from 'react-datepicker'
import Pagination from 'components/Pagination'
import { useTranslation } from 'react-i18next'
// import { useSort } from '@table-library/react-table-library/sort'
import { Table as TableLib, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table'
import { HeaderCellSort, SortIconPositions, SortToggleType } from '@table-library/react-table-library/sort'
import { useNavigate } from 'react-router-dom'
import { FaChevronDown, FaChevronRight, FaRegEdit } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import Modal from 'containers/modal/Modal'
import Title from '../Title'
import Button from '../Button'
import { toast } from 'react-toastify'
import Locale from 'utils/Locale'
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdOutlineDeleteSweep } from 'react-icons/md'
import { getGiftType, getGiftTypeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftTypeSlice'
import { useRequest } from 'hooks/useRequest'
import { getGiftPurpose, getGiftPurposeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftPurposeSlice'
import { getRelationPartyType, getRelationPartyTypeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/relationPartyTypeSlice'
import { getReasonsForRefusal, getReasonsForRefusalFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/reasonsForRefusalSlice'
import { getLocationGroup, getLocationGroupFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/locationGroupSlice'
import { getCurrencies, getCurrenyFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/currencySlice'
import { getUserManagement, getUserManagementFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/userManagementSlice'
import { getRoleManagement, getRoleManagementFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/roleManagementSlice'
import CheckboxTree from 'react-checkbox-tree'
import { RiCheckboxMultipleFill } from 'react-icons/ri'
import { getGiftLimit, getGiftLimitFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftLimitSlice'
import Loading from '../Loading'
import {
	getCompanyHospitality,
	getCompanyHospitalityFilterParametersFunc,
} from 'redux/features/dataManagement/companyHospitality/companyHospitalitySlice'
import { getIncomingGift, getIncomingGiftFilterParametersFunc } from 'redux/features/dataManagement/incomingGift/incomingGiftSlice'
import { getNotification, getNotificationFilterParametersFunc } from 'redux/features/dataManagement/notifications/notificationSlice'
import { getMailTemplate, getMailTemplateFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/mailTemplateSlice'
import { getDraft, getDraftFilterParametersFunc } from 'redux/features/dataManagement/draft/draftSlice'
import { permissionTypes } from 'types/permissionTypes'
import { getApiKey, getApiKeyFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/apiKeySlice'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

const CustomSortIcon = ({ hasText }) => {
	return hasText ? <img src={images.filterico} alt='' style={{ margin: '0px' }} /> : null
}

const Table = ({
	gridCSS,
	sortTable,
	paginationAllFunctionsList,
	columns,
	data,
	filterType,
	search,
	setSearch,
	handleFilterChange,
	setFilters,
	filters,
	isLoading,
	approvedFilterParams
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [request, loading, error] = useRequest()

	const selectRef = useRef(null)
	const selectUserRef1 = useRef(null)
	const selectUserRef2 = useRef(null)

	const {permissions} = useSelector(state => state.configuration)
	const {currentUser} = permissions.configurations

	const { modals } = useSelector((state) => state.general)
	const { adminPanel } = useSelector((state) => state.locationGroup)

	const { modalId, modalStatus, modalToggle, editableData } = modals
	const { getLocationGroupSelectList } = adminPanel

	const [isHovered, setIsHovered] = useState(false)
	const [tabModal, setTabModal] = useState(0)
	const [tabModal2, setTabModal2] = useState(0)

	const [treeCheckedUpdate, setTreeCheckedUpdate] = useState([])
	const [treeExpandedUpdate, setTreeExpandedUpdate] = useState([])

	const [treeCheckedUpdateUserPage, setTreeCheckedUpdateUserPage] = useState([])
	const [treeExpandedUpdateUserPage, setTreeExpandedUpdateUserPage] = useState([])

	const [giftTypeEditStates, setGiftTypeEditStates] = useState({
		name: editableData?.name,
		description: editableData?.description,
		status: editableData?.status,
		incomingApprovalRequired: editableData?.incomingApprovalRequired,
		outgoingApprovalRequired: editableData?.outgoingApprovalRequired,
		excludeFromIncomingLimit: editableData?.excludeFromIncomingLimit,
		excludeFromOutgoingLimit: editableData?.excludeFromOutgoingLimit,
		language: editableData?.translations?.[0]?.language || "",
		tName: editableData?.translations?.[0]?.name || "",
		tDescription: editableData?.translations?.[0]?.description || "",
	})
	const [giftPurposeEditStates, setGiftPurposeEditStates] = useState({
		name: editableData?.name,
		description: editableData?.description,
		status: editableData?.status,
		incomingApprovalRequired: editableData?.incomingApprovalRequired,
		outgoingApprovalRequired: editableData?.outgoingApprovalRequired,
		language: editableData?.translations?.[0]?.language || "",
		tName: editableData?.translations?.[0]?.name || "",
		tDescription: editableData?.translations?.[0]?.description || "",
	})
	const [giftRelatedPartyTypeEditStates, setGiftRelatedPartyTypeEditStates] = useState({
		name: editableData?.name,
		description: editableData?.description,
		status: editableData?.status,
		incomingApprovalRequired: editableData?.incomingApprovalRequired,
		outgoingApprovalRequired: editableData?.outgoingApprovalRequired,
		excludeFromIncomingLimit: editableData?.excludeFromIncomingLimit,
		excludeFromOutgoingLimit: editableData?.excludeFromOutgoingLimit,
		language: editableData?.translations?.[0]?.language || "",
		tName: editableData?.translations?.[0]?.name || "",
		tDescription: editableData?.translations?.[0]?.description || "",
	})
	const [giftReasonForRefusalEditStates, setGiftReasonForRefusalEditStates] = useState({
		name: editableData?.name,
		description: editableData?.description,
		status: editableData?.status,
		language: editableData?.translations?.[0]?.language || "",
		tName: editableData?.translations?.[0]?.name || "",
		tDescription: editableData?.translations?.[0]?.description || "",
	})
	const [locationGroupEditStates, setLocationGroupEditStates] = useState({
		name: editableData?.name,
		code: editableData?.code,
		parentId: {
			value: editableData?.parentId,
			label: editableData?.parentName,
		},
		status: editableData?.status,
		isManagerApprovalRequiredForHospitality: editableData?.isManagerApprovalRequiredForHospitality,
		includeSubCompanies: editableData?.includeSubCompanies,
		canManagersApprove: editableData?.canManagersApprove,
		forCompanyId: editableData?.forCompanyId,
		includeSubCompaniesToLimit: editableData?.includeSubCompaniesToLimit,
	})

	const [currencyEditStates, setCurrencyEditStates] = useState({
		name: editableData?.name,
		code: editableData?.code,
		status: editableData?.status,
		symbol: editableData?.symbol,
		unit: editableData?.unit,
		isDefault: editableData?.isDefault,
	})

	const [userManagementEditStates, setUserManagementEditStates] = useState({
		name: editableData?.name,
		userName: editableData?.userName,
		email: editableData?.email,
		status: editableData?.status,
		isManager: editableData?.isManager,
		surname: editableData?.surname,
		companyId: editableData?.choiceCompanySelectList,
		managerId: editableData?.choiceManagerSelectList,
		roles: editableData?.choiceRolesSelectList,
		substitutedUsers: editableData?.substitutedUsers,
		password: editableData?.password,
		passwordConfirm: editableData?.passwordConfirm,
		companies: editableData?.companies,
	})

	const [apiKeyEditStates, setApiKeyEditStates] = useState({
		expirationDate: editableData?.expirationDate,
		status: editableData?.status,
		companyId: editableData?.choiceCompanySelectList,
		allowedIpAddresses: editableData?.allowedIpAddresses,
		allowedCompanyIds: editableData?.allowedCompanyIds,
		generateNewKey: false,
	})

	const [roleManagementEditStates, setRoleManagementEditStates] = useState({
		name: editableData?.name,
		isDefault: editableData?.isDefault,
		isDefaultForManager: editableData?.isDefaultForManager,
		isStatic: editableData?.isStatic,
		permissions: editableData?.permissions,
	})

	const [giftLimitEditStates, setGiftLimitEditStates] = useState({
		companyId: editableData?.companyId,
		targetCompanyId: editableData?.targetCompanyId,
		year: editableData?.year,
		currencyId: editableData?.currencyId,
		incomingLimit: editableData?.incomingLimit,
		outgoingLimit: editableData?.outgoingLimit,
		//status: editableData?.statusChecked,
		//isGeneralManagerApprovalRequired: editableData?.isGeneralManagerApprovalRequired,
		isManagerApprovalRequiredForIncoming: editableData?.isManagerApprovalRequiredForIncoming,
	})

	const [mailTemplateEditStates, setMailTemplateEditStates] = useState({
		subject: editableData?.subject,
		body: editableData?.body,
		status: editableData?.status,
		language: editableData?.translations?.[0]?.language || "",
		tSubject: editableData?.translations?.[0]?.subject || "",
		tBody: editableData?.translations?.[0]?.body || "",
		
	})


	//OPTIONS STATE ...
	const [companySelectOPTIONS, setCompanySelectOPTIONS] = useState()
	const [eventPurposeSelectOPTIONS, setEventPurposeSelectOPTIONS] = useState()
	const [giftTypeOPTIONS, setGiftTypeOPTIONS] = useState()
	const [departmentOPTIONS, setDepartmentOPTIONS] = useState()
	const [locationOPTIONS, setLocationOPTIONS] = useState()
	const [relatedPartyTypeOPTIONS, setRelatedPartyTypeOPTIONS] = useState()
	const [userSelectOPTIONS, setUserSelectOPTIONS] = useState()
	const [userSubstituteSelectOPTIONS, setUserSubstituteSelectOPTIONS] = useState()

	console.log(departmentOPTIONS?.length, "test");


	useEffect(() => {
		if(editableData?.editItem != "userManagement") return

		if(userManagementEditStates?.companyId?.value)
		{
			request(`/identity/user/select-list?Export=true&OnlyManager=true&CompanyId=${userManagementEditStates?.companyId?.value}`, {
				method: 'GET',
			}).then((res) => {
				if (res.data.ok) {
					setUserSelectOPTIONS(res.data.result.items)
				}
			})

			request(`/identity/user/select-list?Export=true&CompanyId=${userManagementEditStates?.companyId?.value}`, {
				method: 'GET',
			}).then((res) => {
				if (res.data.ok) {
					setUserSubstituteSelectOPTIONS(res.data.result.items?.map((prev) => ({ value: prev.name, label: prev.value })))
				}
			})
		} else {
			setUserSelectOPTIONS([])
			setUserSubstituteSelectOPTIONS([])
		}	
	},[userManagementEditStates?.companyId])


	useEffect(() => {
		if (editableData) {
			setGiftTypeEditStates({
				name: editableData?.name,
				description: editableData?.description,
				status: editableData?.status,
				incomingApprovalRequired: editableData?.incomingApprovalRequired,
				outgoingApprovalRequired: editableData?.outgoingApprovalRequired,
				excludeFromIncomingLimit: editableData?.excludeFromIncomingLimit,
				excludeFromOutgoingLimit: editableData?.excludeFromOutgoingLimit,
				language: editableData?.translations?.[0]?.language || "",
				tName: editableData?.translations?.[0]?.name || "",
				tDescription: editableData?.translations?.[0]?.description || "",
			})
			setGiftPurposeEditStates({
				name: editableData?.name,
				description: editableData?.description,
				status: editableData?.status,
				incomingApprovalRequired: editableData?.incomingApprovalRequired,
				outgoingApprovalRequired: editableData?.outgoingApprovalRequired,
				language: editableData?.translations?.[0]?.language || "",
				tName: editableData?.translations?.[0]?.name || "",
				tDescription: editableData?.translations?.[0]?.description || "",
			})
			setGiftRelatedPartyTypeEditStates({
				name: editableData?.name,
				description: editableData?.description,
				status: editableData?.status,
				incomingApprovalRequired: editableData?.incomingApprovalRequired,
				outgoingApprovalRequired: editableData?.outgoingApprovalRequired,
				excludeFromIncomingLimit: editableData?.excludeFromIncomingLimit,
				excludeFromOutgoingLimit: editableData?.excludeFromOutgoingLimit,
				language: editableData?.translations?.[0]?.language || "",
				tName: editableData?.translations?.[0]?.name || "",
				tDescription: editableData?.translations?.[0]?.description || "",
			})
			setGiftReasonForRefusalEditStates({
				name: editableData?.name,
				description: editableData?.description,
				status: editableData?.status,
				language: editableData?.translations?.[0]?.language || "",
				tName: editableData?.translations?.[0]?.name || "",
				tDescription: editableData?.translations?.[0]?.description || "",
			})
			setLocationGroupEditStates({
				name: editableData?.name,
				code: editableData?.code,
				parentId: {
					value: editableData?.parentId,
					label: editableData?.parentName,
				},
				status: editableData?.status,
				isManagerApprovalRequiredForHospitality: editableData?.isManagerApprovalRequiredForHospitality,
				includeSubCompanies: editableData?.includeSubCompanies,
				canManagersApprove: editableData?.canManagersApprove,
				forCompanyId: editableData?.forCompanyId,
		        includeSubCompaniesToLimit: editableData?.includeSubCompaniesToLimit,
			})
			setCurrencyEditStates({
				name: editableData?.name,
				code: editableData?.code,
				status: editableData?.status,
				symbol: editableData?.symbol,
				unit: editableData?.unit,
				isDefault: editableData?.isDefault,
			})

			setUserManagementEditStates({
				name: editableData?.name,
				userName: editableData?.userName,
				email: editableData?.email,
				status: editableData?.status,
				isManager: editableData?.isManager,
				surname: editableData?.surname,
				companyId: editableData?.choiceCompanySelectList,
				managerId: editableData?.choiceManagerSelectList,
				roles: editableData?.choiceRolesSelectList,
				substitutedUsers: editableData?.substitutedUsers,
				password: editableData?.password,
				passwordConfirm: editableData?.passwordConfirm,
				companies: editableData.companies,
			})

			setApiKeyEditStates({
				expirationDate: editableData?.expirationDate,
				status: editableData?.status,
				companyId: editableData?.choiceCompanySelectList,
				allowedIpAddresses: editableData?.allowedIpAddresses,
				allowedCompanyIds: editableData?.allowedCompanyIds,
				generateNewKey: false,
			})

			setRoleManagementEditStates({
				name: editableData?.name,
				isDefault: editableData?.isDefault,
				isDefaultForManager: editableData?.isDefaultForManager,
				isStatic: editableData?.isStatic,
				permissions: editableData?.permissions,
			})

			setGiftLimitEditStates({
				companyId: editableData?.companyId,
				targetCompanyId: editableData?.targetCompanyId,
				year: editableData?.year,
				currencyId: editableData?.currencyId,
				incomingLimit: editableData?.incomingLimit,
				outgoingLimit: editableData?.outgoingLimit,
				//status: editableData?.statusChecked,
				//isGeneralManagerApprovalRequired: editableData?.isGeneralManagerApprovalRequired,
				isManagerApprovalRequiredForIncoming: editableData?.isManagerApprovalRequiredForIncoming,
			})

			setMailTemplateEditStates({
				subject: editableData?.subject,
				body: editableData?.body,
				status: editableData?.status,
				language: editableData?.translations?.[0]?.language || "",
				tSubject: editableData?.translations?.[0]?.subject || "",
		        tBody: editableData?.translations?.[0]?.body || "",
			})

			// setGiftLimitEditStates({
			// 	companyId: giftLimitCompanyId?.value,
			// 	year: giftLimitYear?.value,
			// 	currencyId: giftLimitCurrencyId?.value,
			// 	incomingLimit: giftLimitIncomingLimit,
			// 	outgoingLimit: giftLimitOutgoingLimit,
			// 	status: statusChecked,
			// 	isGeneralManagerApprovalRequired
			// })

			setTreeCheckedUpdate(editableData?.permissions)
			setTreeExpandedUpdate(editableData?.treeExpanded)

			setTreeCheckedUpdateUserPage(editableData?.companies)
			setTreeExpandedUpdateUserPage(editableData?.treeExpanded)

			if (selectRef.current) {
				selectRef.current.select.setValue({
					value: editableData?.parentId,
					label: editableData?.parentName,
				})
			}
		}
	}, [editableData])

	console.log(apiKeyEditStates, "testtt");

	const endCellStyle = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: '8px',
		//cursor: 'pointer',
	}

	// const colorTheme = {

	//   Row: `
	//       &:hover {
	//         backgroundColor: red;
	//       }

	//       cursor: pointer;
	//     `,
	// };

	const theme = useTheme([
		getTheme(),
		// {
		//   Table: `
		//     --data-table-library_grid-template-columns: repeat(${columns.length}, minmax(150px, 1fr)) ;
		//   `,
		// },
	])
	const [startDate, setStartDate] = useState(false)

	// table options list
	const hasPublicOfficialOptions = [
		{ value: true, label: t('yes') },
		{ value: false, label: t('no') },
	]

	const eventLogOptions = [
		{ value: 0, label: t('Verbose') },
		{ value: 1, label: t('Debug') },
		{ value: 2, label: t('Information') },
		{ value: 3, label: t('Warning') },
		{ value: 4, label: t('Error') },
		{ value: 5, label: t('Fatal') }
	]

	// let companySelectOptions = useMemo(() => {
	// 	const options = []
	// 	request('/company/select-list?Export=true', {
	// 		method: 'GET',
	// 	}).then((res) => {
	// 		if (res.data.ok) {
	// 			options.push(...res.data.result.items)
	// 		}
	// 	})
	// 	return options
	// }, [])

	useEffect(() => {
		//setCompanySelectOPTIONS(companySelectOptions)

		request('/company/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setCompanySelectOPTIONS(res.data.result.items)
			}
		})

		request('/gift/purpose/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setEventPurposeSelectOPTIONS([...res.data.result.items, {description: t('other'), name: 0, value: t('other')}])
			}
		})

		request('/gift/type/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setGiftTypeOPTIONS([...res.data.result.items, {description: t('other'), name: 0, value: t('other')}])
			}
		})

		request('/company/department-select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setDepartmentOPTIONS(res.data.result.items)
			}
		})
		request('/company/location-select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setLocationOPTIONS(res.data.result.items)
			}
		})

		request('/gift/related-party-type/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setRelatedPartyTypeOPTIONS(res.data.result.items)
			}
		})
	}, [])


	const options = [
		{ value: 'deneme1', label: 'Deneme1' },
		{ value: 'deneme2', label: 'Deneme2' },
		{ value: 'deneme3', label: 'Deneme3' },
	]

	const accordingtoApprovalStatusOPTIONS = [
		{ value: 2, label: 'Reddedildi' },
		{ value: 1, label: 'Onaylandı' },
		{ value: 0, label: 'Onay Bekleniyor' },
	]

	const giftPurposeOPTIONS = [
		{ value: 'Çalışanlara Verilen Ödül ve Hediyeler', label: 'Çalışanlara Verilen Ödül ve Hediyeler' },
		{ value: 'İş İlişkilerini Güçlendirme', label: 'İş İlişkilerini Güçlendirme' },
		{ value: 'Müşteri Sadakati Artırma', label: 'Müşteri Sadakati Artırma' },
	]
	// const relatedPartyTypeOPTIONS = [
	// 	{ value: 'Şirket veya Topluluk Şirketi Çalışanı ve Aile Üyeleri', label: 'Şirket veya Topluluk Şirketi Çalışanı ve Aile Üyeleri' },
	// 	{ value: 'İş Ortağı', label: 'İş Ortağı' },
	// 	{ value: 'Müşteri', label: 'Müşteri' },
	// ]
	const reasonForRefusalOPTIONS = [
		{ value: 'Etik Kurallara Aykırılık', label: 'Etik Kurallara Aykırılık' },
		{ value: 'Rüşvet ve Yolsuzluk', label: 'Rüşvet ve Yolsuzluk' },
		{ value: 'Fazla Değerli Hediyeler', label: 'Fazla Değerli Hediyeler' },
	]
	const accordingtoReturnSelectionOPTIONS = [
		{ value: true, label: t('yes') },
		{ value: false, label: t('no') },
	]

	const optionsByReturnSelection = [
		{ value: 'Deneme1', label: 'Deneme1' },
		{ value: 'Deneme2', label: 'Deneme2' },
		{ value: 'Deneme3', label: 'Deneme3' },
		{ value: 'Deneme4', label: 'Deneme4' },
		{ value: 'Deneme5', label: 'Deneme5' },
		{ value: 'Deneme6', label: 'Deneme6' },
		{ value: 'Deneme7', label: 'Deneme7' },
	]


	const tableRowFunc = (item) => {
		if (window.location.pathname.split('/')?.[1] == 'admin-panel' || window.location.pathname.split('/')?.[1] == 'notification' || window.location.pathname.split('/')?.[1] == '') {
			return 
		}else if(window.location.pathname.split('/')?.[1] == 'draft'){
			if(item.type == 1){
				return navigate(`/incoming-gift/add?draft=true`, {state : item?.formData})           
			}else if(item.type == 2){
				return navigate(`/outgoing-gift/add?draft=true`, {state : item?.formData})
			}else{
				return navigate(`/company-hospitality/add?draft=true`, {state : item?.formData})
			}
		}
		navigate(`${window.location.pathname}/${item.id}`)
	}

	const handleEdit = (item) => {
		dispatch(openModal({ open: true, type: { modalId: null, modalStatus: 'adminPanel-editModal', editableData: item } }))
	}

	const deleteRow = () => {
		if (editableData.deleteItem == 'gift-type') {
			request(`/gift/type/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/gift/type', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getGiftTypeFilterParametersFunc(filterPrm))
							dispatch(getGiftType(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'gift-purpose') {
			request(`/gift/purpose/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/gift/purpose', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getGiftPurposeFilterParametersFunc(filterPrm))
							dispatch(getGiftPurpose(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'related-partyType') {
			request(`/gift/related-party-type/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/gift/related-party-type', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getRelationPartyTypeFilterParametersFunc(filterPrm))
							dispatch(getRelationPartyType(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'reasons-refusal') {
			request(`/gift/reject-reason/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/gift/reject-reason', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getReasonsForRefusalFilterParametersFunc(filterPrm))
							dispatch(getReasonsForRefusal(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'location-group') {
			request(`/company/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/company', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getLocationGroupFilterParametersFunc(filterPrm))
							dispatch(getLocationGroup(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'currency') {
			request(`/currency/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/currency', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getCurrenyFilterParametersFunc(filterPrm))
							dispatch(getCurrencies(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'userManagement') {
			request(`/identity/user/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/identity/user', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getUserManagementFilterParametersFunc(filterPrm))
							dispatch(getUserManagement(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'roleManagement') {
			request(`/identity/role/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/identity/role', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getRoleManagementFilterParametersFunc(filterPrm))
							dispatch(getRoleManagement(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'gift-limit') {
			request(`/company-gift-limit/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/company-gift-limit', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getGiftLimitFilterParametersFunc(filterPrm))
							dispatch(getGiftLimit(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'company-hospitality') {
			request(`/company-hospitality/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/company-hospitality', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getCompanyHospitalityFilterParametersFunc(filterPrm))
							dispatch(getCompanyHospitality(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'incoming-gift') {
			request(`/gift-transaction/incoming/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/gift-transaction/incoming', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getIncomingGiftFilterParametersFunc(filterPrm))
							dispatch(getIncomingGift(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'notification') {
			request(`/notification/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/notification', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getNotificationFilterParametersFunc(filterPrm))
							dispatch(getNotification(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		} else if (editableData.deleteItem == 'draft') {
			request(`/draft-form/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/draft-form', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getDraftFilterParametersFunc(filterPrm))
							dispatch(getDraft(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		}else if (editableData.deleteItem == 'apiKey') {
			request(`/api-key/${editableData.id}`, {
				method: 'DELETE',
			}).then((res) => {
				if (res.data.ok) {
					request('/api-key', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getApiKeyFilterParametersFunc(filterPrm))
							dispatch(getApiKey(res.data.result.items))
						}
					})
				}
				toast.success(t('deletionsuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				}, 100)
			})
		}
	}

	const editRow = () => {
		if (editableData.editItem == 'gift-type') {
			request(`/gift/type`, {
				method: 'POST',
				data: {
					id: editableData.id,
					name: giftTypeEditStates.name,
					description: giftTypeEditStates.description,
					status: giftTypeEditStates.status,
					incomingApprovalRequired: giftTypeEditStates?.incomingApprovalRequired,
					outgoingApprovalRequired: giftTypeEditStates?.outgoingApprovalRequired,
					excludeFromIncomingLimit: giftTypeEditStates?.excludeFromIncomingLimit,
					excludeFromOutgoingLimit: giftTypeEditStates?.excludeFromOutgoingLimit,
					translations: (giftTypeEditStates?.tName || giftTypeEditStates?.tDescription) ? [{
						language: "en",
						name: giftTypeEditStates.tName,
						description: giftTypeEditStates.tDescription
					}] : []
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/gift/type', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getGiftTypeFilterParametersFunc(filterPrm))
							dispatch(getGiftType(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setGiftTypeEditStates({
						name: '',
						description: '',
						status: '',
						incomingApprovalRequired: '',
						outgoingApprovalRequired: '',
						excludeFromIncomingLimit: '',
						excludeFromOutgoingLimit: '',
						tName: '',
						tDescription: ''
					})
				}, 100)
			})
		} else if (editableData.editItem == 'gift-purpose') {
			request(`/gift/purpose`, {
				method: 'POST',
				data: {
					id: editableData.id,
					name: giftPurposeEditStates.name,
					description: giftPurposeEditStates.description,
					status: giftPurposeEditStates.status,
					incomingApprovalRequired: giftPurposeEditStates?.incomingApprovalRequired,
					outgoingApprovalRequired: giftPurposeEditStates?.outgoingApprovalRequired,
					translations: (giftPurposeEditStates?.tName || giftPurposeEditStates?.tDescription) ? [{
						language: "en",
						name: giftPurposeEditStates.tName,
						description: giftPurposeEditStates.tDescription
					}] : []
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/gift/purpose', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getGiftPurposeFilterParametersFunc(filterPrm))
							dispatch(getGiftPurpose(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setGiftPurposeEditStates({ name: '', description: '', status: '', incomingApprovalRequired: '', outgoingApprovalRequired: '', tName: '',
					tDescription: '' })
				}, 100)
			})
		} else if (editableData.editItem == 'related-partyType') {
			request(`/gift/related-party-type`, {
				method: 'POST',
				data: {
					id: editableData.id,
					name: giftRelatedPartyTypeEditStates.name,
					description: giftRelatedPartyTypeEditStates.description,
					status: giftRelatedPartyTypeEditStates.status,
					incomingApprovalRequired: giftRelatedPartyTypeEditStates?.incomingApprovalRequired,
					outgoingApprovalRequired: giftRelatedPartyTypeEditStates?.outgoingApprovalRequired,
					excludeFromIncomingLimit: giftRelatedPartyTypeEditStates?.excludeFromIncomingLimit,
					excludeFromOutgoingLimit: giftRelatedPartyTypeEditStates?.excludeFromOutgoingLimit,
					translations: (giftRelatedPartyTypeEditStates?.tName || giftRelatedPartyTypeEditStates?.tDescription) ? [{
						language: "en",
						name: giftRelatedPartyTypeEditStates.tName,
						description: giftRelatedPartyTypeEditStates.tDescription
					}] : []
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/gift/related-party-type', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getRelationPartyTypeFilterParametersFunc(filterPrm))
							dispatch(getRelationPartyType(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setGiftRelatedPartyTypeEditStates({
						name: '',
						description: '',
						status: '',
						incomingApprovalRequired: '',
						outgoingApprovalRequired: '',
						excludeFromIncomingLimit: '',
						excludeFromOutgoingLimit: '',
						tName: '',
						tDescription: ''
					})
				}, 100)
			})
		} else if (editableData.editItem == 'reasons-refusal') {
			request(`/gift/reject-reason`, {
				method: 'POST',
				data: {
					id: editableData.id,
					name: giftReasonForRefusalEditStates.name,
					description: giftReasonForRefusalEditStates.description,
					status: giftReasonForRefusalEditStates.status,
					translations: (giftReasonForRefusalEditStates?.tName || giftReasonForRefusalEditStates?.tDescription) ? [{
						language: "en",
						name: giftReasonForRefusalEditStates.tName,
						description: giftReasonForRefusalEditStates.tDescription
					}] : []
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/gift/reject-reason', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getReasonsForRefusalFilterParametersFunc(filterPrm))
							dispatch(getReasonsForRefusal(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setGiftReasonForRefusalEditStates({ name: '', description: '', status: '',	tName: '', tDescription: '' })
				}, 100)
			})
		} else if (editableData.editItem == 'location-group') {
			request(`/company`, {
				method: 'POST',
				data: {
					id: editableData.id,
					name: locationGroupEditStates.name,
					code: locationGroupEditStates.code,
					parentId: locationGroupEditStates.parentId?.value,
					status: locationGroupEditStates.status,
					isManagerApprovalRequiredForHospitality: locationGroupEditStates.isManagerApprovalRequiredForHospitality,
					includeSubCompanies: locationGroupEditStates?.includeSubCompanies,
					canManagersApprove: locationGroupEditStates?.canManagersApprove,
					forCompanyId: locationGroupEditStates?.forCompanyId,
		            includeSubCompaniesToLimit: locationGroupEditStates?.includeSubCompaniesToLimit,
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/company', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getLocationGroupFilterParametersFunc(filterPrm))
							dispatch(getLocationGroup(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setLocationGroupEditStates({ name: '', code: '', status: '', parentId: '',isManagerApprovalRequiredForHospitality:'' })
				}, 100)
			})
		} else if (editableData.editItem == 'currency') {
			request(`/currency`, {
				method: 'POST',
				data: {
					id: editableData.id,
					name: currencyEditStates.name,
					code: currencyEditStates.code,
					symbol: currencyEditStates.symbol,
					unit: currencyEditStates.unit,
					isDefault: currencyEditStates.isDefault,
					status: currencyEditStates.status,
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/currency', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getCurrenyFilterParametersFunc(filterPrm))
							dispatch(getCurrencies(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setCurrencyEditStates({ name: '', code: '', symbol: '', isDefault: '', status: '', unit: '' })
				}, 100)
			})
		} else if (editableData.editItem == 'userManagement') {
			request(`/identity/user`, {
				method: 'POST',
				data: {
					id: editableData.id,
					name: userManagementEditStates.name,
					surname: userManagementEditStates.surname,
					email: userManagementEditStates.email,
					userName: userManagementEditStates.userName,
					roles: userManagementEditStates?.roles?.map((role) => role.value),
					substitutedUsers:userManagementEditStates?.substitutedUsers?.map((prev) => prev.value),
					companyId: userManagementEditStates.companyId?.value,
					managerId: userManagementEditStates.managerId?.value,
					status: userManagementEditStates.status,
					isManager: userManagementEditStates.isManager,
					password: userManagementEditStates.password,
					passwordConfirm: userManagementEditStates.passwordConfirm,
					companies: treeCheckedUpdateUserPage
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/identity/user', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getUserManagementFilterParametersFunc(filterPrm))
							dispatch(getUserManagement(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setUserManagementEditStates({ name: '', surname: '', userName: '', email: '', roles: '', companyId: '', status: '', isManager:'', managerId: '' })
				    setTabModal2(0)
				}, 100)
			})
		} else if (editableData.editItem == 'roleManagement') {
			request(`/identity/role`, {
				method: 'POST',
				data: {
					id: editableData.id,
					name: roleManagementEditStates.name,
					isDefault: roleManagementEditStates.isDefault,
					isDefaultForManager: roleManagementEditStates.isDefaultForManager,
					isStatic: roleManagementEditStates.isStatic,
					permissions: treeCheckedUpdate,
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/identity/role', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getRoleManagementFilterParametersFunc(filterPrm))
							dispatch(getRoleManagement(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					setTabModal(0)
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setRoleManagementEditStates({ name: '', isDefault: '', isStatic: '', isDefaultForManager: '', permissions: [] })
				}, 100)
			})
		} else if (editableData.editItem == 'gift-limit') {
			request(`/company-gift-limit`, {
				method: 'POST',
				data: {
					id: editableData.id,
					companyId: giftLimitEditStates?.companyId,
					targetCompanyId: giftLimitEditStates?.targetCompanyId,
					year: giftLimitEditStates?.year,
					currencyId: giftLimitEditStates?.currencyId,
					incomingLimit: giftLimitEditStates?.incomingLimit,
					outgoingLimit: giftLimitEditStates?.outgoingLimit,
					//isGeneralManagerApprovalRequired: giftLimitEditStates?.isGeneralManagerApprovalRequired == 1 ? true : false,
					isManagerApprovalRequiredForIncoming: giftLimitEditStates?.isManagerApprovalRequiredForIncoming == 1 ? true : false,
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/company-gift-limit', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getGiftLimitFilterParametersFunc(filterPrm))
							dispatch(getGiftLimit(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setRoleManagementEditStates({ name: '', isDefault: '', isDefaultForManager: '', isStatic: '', permissions: [] })
				}, 100)
			})
		} else if (editableData.editItem == 'mailTemplate') {
			request(`/email-template`, {
				method: 'POST',
				data: {
					id: editableData?.id,
					subject: mailTemplateEditStates?.subject,
					body: mailTemplateEditStates?.body,
					status: mailTemplateEditStates?.status,
					translations: (mailTemplateEditStates?.tSubject && mailTemplateEditStates?.tBody) ? [{
						language: "en",
						subject: mailTemplateEditStates.tSubject,
						body: mailTemplateEditStates.tBody
					}] : []
				}			
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/email-template', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getMailTemplateFilterParametersFunc(filterPrm))
							dispatch(getMailTemplate(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setMailTemplateEditStates({ body: '', subject: '', status: 0, tBody: '', tSubject: '', language: 'en' })
				}, 100)
			})
		}else if (editableData.editItem == 'apiKey') {
			request(`/api-key`, {
				method: 'POST',
				data: {
					id: editableData?.id,
					expirationDate: apiKeyEditStates?.expirationDate,
					status: apiKeyEditStates?.status,
					companyId: apiKeyEditStates?.companyId?.value,
					allowedIpAddresses: apiKeyEditStates?.allowedIpAddresses,
					allowedCompanyIds: treeCheckedUpdateUserPage,
					generateNewKey: apiKeyEditStates?.generateNewKey,
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/api-key', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getApiKeyFilterParametersFunc(filterPrm))
							dispatch(getApiKey(res.data.result.items))
						}
					})
				}
				toast.success(t('updatesuccessful'))
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setApiKeyEditStates({ expirationDate: '', generateNewKey: false, companyId: '', status: '' })
				    setTabModal2(0)
				}, 100)
				if(res.data.result){
					setTimeout(() => {
						dispatch(openModal({ open: true, type: { modalId: res.data.result, modalStatus: 'getApiKey' } }))
					}, 150)
				}		
			})
		}
	}


	return (
		<div className='table'>
			{/* Burada ayrı bir component ya da switch-case yapısı özellikle kullanılmadı.Filtre yapısı ile alakalı. */}
			{filterType == 0 && (
				<div className='table-filter'>
					<Input
						//labelColor='transparent'
						label={t('companyName')}
						placeholder={t('search')}
						type='text'
						name='search'
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('approvalStatus', value ? value.value : '')}
						type={0}
						defaultValue={approvedFilterParams == 0 ? 
							{ value: 1, label: 'Onaylandı' } : approvedFilterParams == 1 ?
							{ value: 2, label: 'Reddedildi' } : approvedFilterParams == 2 ?
							{ value: 0, label: 'Onay Bekleniyor' } : ''
						}
						options={accordingtoApprovalStatusOPTIONS}
						placeholder={t('select')}
						label={t('accordingtoApprovalStatus')}
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('giftTypeId', value ? value.value : '')}
						type={0}
						options={giftTypeOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('accordingtoGiftType')}
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('giftPurposeId', value ? value.value : '')}
						type={0}
						options={eventPurposeSelectOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('accordingtoGiftReason')}
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('isReturned', value ? value.value : '')}
						type={0}
						options={accordingtoReturnSelectionOPTIONS}
						placeholder={t('select')}
						label={t('accordingtoReturnSelection')}
					/>
					<div className='table-filter-date'>
						<label htmlFor=''>{t('accordingtoDate')}</label>
						<div className='table-filter-date-container'>
						<DatePicker
							placeholderText={t('startDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.startDate}
							onChange={(date) => handleFilterChange('startDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						<DatePicker
							placeholderText={t('endDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.endDate}
							onChange={(date) => handleFilterChange('endDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						</div>
						
					</div>

					<SelectDr
						onChange={(value) => handleFilterChange('giftRelatedPartyTypeId', value ? value.value : '')}
						type={0}
						options={relatedPartyTypeOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('giftSenderRelated')}
					/>
					
					{
					     (currentUser?.permissions[permissionTypes.GiftsViewOwnCompany] ||
						 currentUser?.permissions[permissionTypes.ViewAllCompanies]) &&
						<SelectDr
						onChange={(value) => handleFilterChange('department', value ? value.value : '')}
						type={0}
						options={departmentOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('department')}
					   />
					}

{
					     (currentUser?.permissions[permissionTypes.GiftsViewOwnCompany] ||
						 currentUser?.permissions[permissionTypes.ViewAllCompanies]) &&
						<SelectDr
						onChange={(value) => handleFilterChange('location', value ? value.value : '')}
						type={0}
						options={locationOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('location')}
					   />
					}
					
					{
						 //item.complianceManagerApprovalDate && item.managerApprove && item.generalManagerApprove
						 (currentUser?.permissions[permissionTypes.ComplianceManagerApprovalDate] ||
						 currentUser?.permissions[permissionTypes.ManagerApprove] ||
						 currentUser?.permissions[permissionTypes.GeneralManagerApprove]) &&
						 <div style={{display: "flex", alignItems: "center", gap: "4px", fontSize: "13px", marginTop: "3px"}}>
						<input
						style={{width: "16px", height: "16px"}}
						onChange={(e) =>  handleFilterChange('waitingForMyApproval', e.target.checked)}
						type="checkbox" id='check'/>
						<label htmlFor="check">{t('waitingForMyApproval')}</label>
					</div>

					}
					
							
					{/* <SelectDr
						onChange={(value) => handleFilterChange('waitingForMyApproval', value ? value.value : '')}
						type={0}
						options={accordingtoReturnSelectionOPTIONS}
						placeholder={t('select')}
						label={t('waitingForMyApproval')}
					/> */}
				</div>
			)}

			{filterType == 1 && (
				<div className='table-filter'>
					<Input
						//labelColor='transparent'
						label={t('companyName')}
						placeholder={t('search')}
						type='text'
						name='search'
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('approvalStatus', value ? value.value : '')}
						type={0}
						defaultValue={approvedFilterParams == 0 ? 
							{ value: 1, label: 'Onaylandı' } : approvedFilterParams == 1 ?
							{ value: 2, label: 'Reddedildi' } : approvedFilterParams == 2 ?
							{ value: 0, label: 'Onay Bekleniyor' } : ''
						}
						options={accordingtoApprovalStatusOPTIONS}
						placeholder={t('select')}
						label={t('accordingtoApprovalStatus')}
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('giftTypeId', value ? value.value : '')}
						type={0}
						options={giftTypeOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('accordingtoGiftType')}
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('giftPurposeId', value ? value.value : '')}
						type={0}
						options={eventPurposeSelectOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('accordingtoGiftReason')}
					/>
					<div className='table-filter-date'>
						<label htmlFor=''>{t('accordingtoDate')}</label>
						<div className='table-filter-date-container'>
						<DatePicker
							placeholderText={t('startDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.startDate}
							onChange={(date) => handleFilterChange('startDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						<DatePicker
							placeholderText={t('endDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.endDate}
							onChange={(date) => handleFilterChange('endDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						</div>
					</div>
					<SelectDr
						onChange={(value) => handleFilterChange('giftRelatedPartyTypeId', value ? value.value : '')}
						type={0}
						options={relatedPartyTypeOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('partyToWhichTheGiftWasSent')}
					/>
					{
						 (currentUser?.permissions[permissionTypes.GiftsViewOwnCompany] ||
						 currentUser?.permissions[permissionTypes.ViewAllCompanies]) &&
						<SelectDr
						onChange={(value) => handleFilterChange('department', value ? value.value : '')}
						type={0}
						options={departmentOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('department')}
					   />
					}
					{
						 (currentUser?.permissions[permissionTypes.GiftsViewOwnCompany] ||
						 currentUser?.permissions[permissionTypes.ViewAllCompanies]) &&
						<SelectDr
						onChange={(value) => handleFilterChange('location', value ? value.value : '')}
						type={0}
						options={locationOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('location')}
					   />
					}
					
					{
						 //item.complianceManagerApprovalDate && item.managerApprove && item.generalManagerApprove
						 (currentUser?.permissions[permissionTypes.ComplianceManagerApprovalDate] ||
						 currentUser?.permissions[permissionTypes.ManagerApprove] ||
						 currentUser?.permissions[permissionTypes.GeneralManagerApprove]) &&
						 <div style={{display: "flex", alignItems: "center", gap: "4px", fontSize: "13px", marginTop: "3px"}}>
						<input
						style={{width: "16px", height: "16px"}}
						onChange={(e) =>  handleFilterChange('waitingForMyApproval', e.target.checked)}
						type="checkbox" id='check'/>
						<label htmlFor="check">{t('waitingForMyApproval')}</label>
					</div>

					}
				</div>
			)}

			{filterType == 2 && (
				<div className='table-filter'>
					<Input
						//labelColor='transparent'
						label={t('companyName')}
						placeholder={t('search')}
						type='text'
						name='search'
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('approvalStatus', value ? value.value : '')}
						type={0}
						defaultValue={approvedFilterParams == 0 ? 
							{ value: 1, label: 'Onaylandı' } : approvedFilterParams == 1 ?
							{ value: 2, label: 'Reddedildi' } : approvedFilterParams == 2 ?
							{ value: 0, label: 'Onay Bekleniyor' } : ''
						}
						options={accordingtoApprovalStatusOPTIONS}
						placeholder={t('select')}
						label={t('accordingtoApprovalStatus')}
					/>
					{/* <SelectDr 
					   onChange={(value) => handleFilterChange('companyId', value ? value.value : '')}
					   type={0} 
					   options={companySelectOPTIONS?.map(prev => ({value: prev.name, label: prev.value}))} 
					   placeholder={t('select')} 
					   label={t('companyName')} 
					/> */}
					<SelectDr
						onChange={(value) => handleFilterChange('hasPublicOfficial', value ? value.value : '')}
						type={0}
						options={hasPublicOfficialOptions}
						placeholder={t('select')}
						label={t('isTherePublicOfficial')}
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('eventPurposeId', value ? value.value : '')}
						type={0}
						options={eventPurposeSelectOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('eventPurpose')}
					/>
					<div className='table-filter-date'>
						<label htmlFor=''>{t('byDate')}</label>
						<div className='table-filter-date-container'>
						<DatePicker
							placeholderText={t('startDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.startDate}
							onChange={(date) => handleFilterChange('startDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						<DatePicker
							placeholderText={t('endDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.endDate}
							onChange={(date) => handleFilterChange('endDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						</div>
					</div>
					{
						 (currentUser?.permissions[permissionTypes.GiftsViewOwnCompany] ||
						 currentUser?.permissions[permissionTypes.ViewAllCompanies]) &&
						<SelectDr
						onChange={(value) => handleFilterChange('department', value ? value.value : '')}
						type={0}
						options={departmentOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('department')}
					   />
					}
					{
						 (currentUser?.permissions[permissionTypes.GiftsViewOwnCompany] ||
						 currentUser?.permissions[permissionTypes.ViewAllCompanies]) &&
						<SelectDr
						onChange={(value) => handleFilterChange('location', value ? value.value : '')}
						type={0}
						options={locationOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('location')}
					   />
					}
					
					{
						 //item.complianceManagerApprovalDate && item.managerApprove && item.generalManagerApprove
						 (currentUser?.permissions[permissionTypes.ComplianceManagerApprovalDate] ||
						 currentUser?.permissions[permissionTypes.ManagerApprove] ||
						 currentUser?.permissions[permissionTypes.GeneralManagerApprove]) &&
						 <div style={{display: "flex", alignItems: "center", gap: "4px", fontSize: "13px", marginTop: "3px"}}>
						<input
						style={{width: "16px", height: "16px"}}
						onChange={(e) =>  handleFilterChange('waitingForMyApproval', e.target.checked)}
						type="checkbox" id='check'/>
						<label htmlFor="check">{t('waitingForMyApproval')}</label>
					</div>

					}
				</div>
			)}

			{filterType == 3 && (
				<div className='table-filter'>
					<SelectDr type={1} options={options} placeholder={t('select')} label={t('filter')} />
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
				</div>
			)}
			{filterType == 4 && (
				<div className='table-filter'>
					<SelectDr
						onChange={(value) => handleFilterChange('companyId', value ? value.value : '')}
						type={0}
						options={companySelectOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('companyName')}
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('targetCompanyId', value ? value.value : '')}
						type={0}
						options={companySelectOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('giftRelatedOrganizationName')}
					/>
					<Input
						//onChange={(e) => handleFilterChange('year', value ? value.value : '')}
						labelColor=''
						label={t('searchbyYear')}
						placeholder={t('search')}
						type='number'
						name='year'
						//value={search}
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
				</div>
			)}
			{filterType == 5 && (
				<div className='table-filter'>
					<Input
						//onChange={(e) => handleFilterChange('year', value ? value.value : '')}
						//labelColor='transparent'
						label={t('username')}
						placeholder={t('search')}
						type='text'
						name='search'
						//value={search}
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('companyId', value ? value.value : '')}
						type={0}
						options={companySelectOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('select')}
						label={t('companyName')}
					/>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
				</div>
			)}
			{filterType == 6 && (
				<div className='table-filter'>
					<Input
						//labelColor='transparent'
						label={t('companyName')}
						placeholder={t('search')}
						type='text'
						name='search'
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					 <div style={{display: "flex", alignItems: "center", gap: "4px", fontSize: "13px", marginTop: "3px"}}>
						<input
						style={{width: "16px", height: "16px"}}
						onChange={(e) =>  handleFilterChange('includeCompanySpecific', e.target.checked)}
						type="checkbox" id='check'/>
						<label htmlFor="check">{t('includeCompanySpecific')}</label>
					</div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
				</div>
			)}
			{filterType == 7 && (
				<div className='table-filter'>
					<Input
						//labelColor='transparent'
						label={t('search')}
						placeholder={t('search')}
						type='text'
						name='search'
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<div className='table-filter-date'>
						<label htmlFor=''>{t('createdDate')}</label>
						<div className='table-filter-date-container'>
						<DatePicker
							placeholderText={t('startDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.startDate}
							onChange={(date) => handleFilterChange('startDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						<DatePicker
							placeholderText={t('endDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.endDate}
							onChange={(date) => handleFilterChange('endDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						</div>
					</div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
				</div>
			)}

			{filterType == 8 && (
				<div className='table-filter'>
					<Input
						//labelColor='transparent'
						label={t('search')}
						placeholder={t('search')}
						type='text'
						name='search'
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<SelectDr
						onChange={(value) => handleFilterChange('logLevel', value ? value.value : '')}
						type={0}
						options={eventLogOptions}
						placeholder={t('select')}
						label={t('logLevel')}
					/>
					<div className='table-filter-date'>
						<label htmlFor=''>{t('logDate')}</label>
						<div className='table-filter-date-container'>
						<DatePicker
							placeholderText={t('startDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.startDate}
							onChange={(date) => handleFilterChange('startDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						<DatePicker
							placeholderText={t('endDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.endDate}
							onChange={(date) => handleFilterChange('endDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						</div>
					</div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
				</div>
			)}

			{filterType == 9 && (
				<div className='table-filter'>
					<Input
						//labelColor='transparent'
						label={t('search')}
						placeholder={t('search')}
						type='text'
						name='search'
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<div className='table-filter-date'>
						<label htmlFor=''>{t('auditDate')}</label>
						<div className='table-filter-date-container'>
						<DatePicker
							placeholderText={t('startDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.startDate}
							onChange={(date) => handleFilterChange('startDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						<DatePicker
							placeholderText={t('endDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={filters?.endDate}
							onChange={(date) => handleFilterChange('endDate', date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
						</div>
					</div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
				</div>
			)}
			{filterType == 10 && (
				<div className='table-filter'>
					<Input
						//labelColor='transparent'
						label={t('search')}
						placeholder={t('search')}
						type='text'
						name='search'
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<Input
						//labelColor='transparent'
						label={t('year')}
						placeholder={t('year')}
						defaultValue={filters?.year}
						type='number'
						name='year'
						setValue={setFilters}
						multipleState={true}
						addDebounce={true} //debounce adding
					/>
					<div style={{display: "flex", alignItems: "center", gap: "4px", fontSize: "13px", marginTop: "3px"}}>
						<input
						style={{width: "16px", height: "16px"}}
						onChange={(e) =>  handleFilterChange('onlyLimitExceeded', e.target.checked)}
						type="checkbox" id='check'/>
						<label htmlFor="check">{t('onlyLimitExceeded')}</label>
					</div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
					<div className='nullDiv'> </div>
				</div>
			)}

			{/* <CompactTable 
         onRowClick={handleRowClick} 
         layout={{ horizontalScroll: true }} 
         pagination={pagination} 
         columns={columns}
         data={data} 
         theme={theme} 
         sort={sort} 
      /> */}
			{data.nodes.length > 0 ? (
				isLoading ? (
					<div className='panel-table-loading'>
						<Loading width='45px' height='45px' borderWidth='5px' />
					</div>
				) : (
					<>
						<div className={`table-container ${gridCSS}`}>
							<TableLib theme={theme} layout={{ custom: true, horizontalScroll: true }} data={data}>
								{(tableList) => (
									<>
										<Header>
											<HeaderRow>
												{columns.map((column) =>
													column.label ? (
														<HeaderCell title={column.label} onClick={(e) => sortTable(e, column)} key={column.key}>
															{column.label}
															<img src={images.filterico} alt='' style={{ margin: '0px' }} />
														</HeaderCell>
													) : (
														<HeaderCell key={column.key}></HeaderCell>
													)
												)}
											</HeaderRow>
										</Header>

										<Body>
											{tableList.map((item) => {
												return (
													<Row
														key={item.id}
														item={item}
														className={`table-row ${isHovered ? 'table-row-hover' : ''} ${item.status == 0 ? 'passiveCell' : ''}`}
														onClick={tableRowFunc}
														onMouseEnter={() =>
															window.location.pathname.split('/')[1] != 'admin-panel' &&
															window.location.pathname.split('/')[1] != 'notification' &&
															window.location.pathname.split('/')[1] != '' &&
															setIsHovered(true)
														}
														onMouseLeave={() =>
															window.location.pathname.split('/')[1] != 'admin-panel' &&
															window.location.pathname.split('/')[1] != 'notification' &&
															window.location.pathname.split('/')[1] != '' &&
															setIsHovered(false)
														}
													>
														{columns.map((column) => (
															// <Cell key={column.key}>{column.renderCell(item)}</Cell>
															<Cell key={column.key}>
																{column.name != 'status' && column.renderCell(item)}
																{/* Durum 1 - Düzenle Butonun kontrolü */}
																{/* {column.label === '' && window.location.pathname.split('/')[2] == 'gift-limit' && (
																<div className='cursor' style={endCellStyle} onClick={(e) => e.stopPropagation()}>
																	<FaRegEdit onClick={() => handleEdit(item)} />
																	<div onClick={() => handleEdit(item)} className='cabin-semibold'>
																		{t('edit')}
																	</div>
																</div>
															)} */}
															</Cell>
														))}
													</Row>
												)
											})}
										</Body>
									</>
								)}
							</TableLib>
						</div>
						<Pagination paginationAllFunctionsList={paginationAllFunctionsList} data={data} />
					</>
				)
			) : (
				<div className='table-non-data'>{t('thereisnosuchdata')}</div>
			)}

			{modalToggle && modalStatus == 'adminPanel-editModal' && (
				<Modal customModal={editableData?.editItem == 'userManagement' || editableData?.editItem == 'gift-limit' ? 'userModal userModalUpd' : ''}>
					{editableData.editItem == 'roleManagement' ? (
						<div className='tab-modal'>
							<div className='tab-modal-lgTitle'>
								<Title name={t('updateRole')} color='#242424' fontweight='bold' size='24px' />
							</div>
							<div className='tab-modal-titles'>
								<div className={tabModal == 0 ? 'active' : ''} onClick={() => setTabModal(0)}>
								{t('role')}
								</div>
								<div className={tabModal == 1 ? 'active' : ''} onClick={() => setTabModal(1)}>
								{t('permits')}
								</div>
							</div>
							{tabModal == 0 ? (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
										<Input
											readOnly={roleManagementEditStates.isStatic ? true : false}
											name={'name'}
											multipleState={true}
											value={roleManagementEditStates.name}
											setValue={setRoleManagementEditStates}
											labelColor='#292929'
											label={t('roleName')}
											type='text'
										/>
									</div>

									<div style={{ marginTop: '20px' }} className='tab-modal-tab1-checkArea'>
										<input
											disabled={roleManagementEditStates.isStatic ? true : false}
											checked={roleManagementEditStates.isDefault == true ? true : false}
											onChange={(e) => setRoleManagementEditStates((prev) => ({ ...prev, isDefault: e.target.checked }))}
											type='checkbox'
											name=''
											id=''
										/>
										<div>{t('default')}</div>
									</div>
									<div style={{ marginTop: '7px' }} className='tab-modal-tab1-checkArea'>
										<input
											disabled={roleManagementEditStates.isStatic ? true : false}
											checked={roleManagementEditStates.isDefaultForManager == true ? true : false}
											onChange={(e) => setRoleManagementEditStates((prev) => ({ ...prev, isDefaultForManager: e.target.checked }))}
											type='checkbox'
											name=''
											id=''
										/>
										<div>{t('defaultAdministratorRole')}</div>
									</div>
									<div className='tab-modal-tab1-checkArea'>
										<input
											readOnly
											checked={roleManagementEditStates.isStatic == true ? true : false}
											//onChange={(e) => setRoleManagementEditStates(prev => ({...prev, isStatic: e.target.checked}))}
											type='checkbox'
											name=''
											id=''
										/>
										<div>{t('fixed')}</div>
									</div>
								</div>
							) : (
								<div className='tab-modal-tab2'>
									<CheckboxTree
										nodes={editableData?.treeNodes}
										noCascade={true}
										checked={treeCheckedUpdate}
										expanded={treeExpandedUpdate}
										onCheck={(checked) => (roleManagementEditStates.isStatic == true ? null : setTreeCheckedUpdate(checked))}
										onExpand={(expanded) => setTreeExpandedUpdate(expanded)}
										//iconsClass="fa5"
										icons={{
											check: <MdOutlineCheckBox size={24} />,
											uncheck: <MdOutlineCheckBoxOutlineBlank size={24} />,
											leaf: <RiCheckboxMultipleFill />,
											expandClose: <FaChevronRight />,
											expandOpen: <FaChevronDown />,
										}}
										//expandIconRenderer={renderExpandIcon}
									/>
								</div>
							)}
							<div className='tab-modal-modalGroup'>
								<Button
									onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
									name={t('cancel')}
									color='#fff'
									backgroundColor='#c1312f'
									height='48px'
								/>
								<Button onclick={editRow} name={t('update')} color='#fff' backgroundColor='#1392e5' height='48px' />
							</div>
						</div>
					) : 
					editableData.editItem == 'userManagement' ? (
						<div className='tab-modal'>
							<div className='tab-modal-lgTitle'>
								<Title name={t('updateUser')} color='#242424' fontweight='bold' size='24px' />
							</div>
							<div className='tab-modal-titles'>
								<div className={tabModal == 0 ? 'active' : ''} onClick={() => setTabModal(0)}>
								{t('user')}
								</div>
								<div className={tabModal == 1 ? 'active' : ''} onClick={() => setTabModal(1)}>
								{t('companies')}
								</div>
							</div>
							{tabModal == 0 ? (
								<div className='tab-modal-tab1'>
									<div className='updContainerUserMng'>
								<div className='userManagement-addingModal lastUpdateUserManagementModal'>
									<Input
										readOnly={editableData?.externalId ? true : false}
										name='name'
										value={userManagementEditStates?.name}
										multipleState={true}
										setValue={setUserManagementEditStates}
										labelColor='#292929'
										label={t('name')}
										placeholder=''
										type='text'
									/>
									<Input
										readOnly={editableData?.externalId ? true : false}
										name='surname'
										value={userManagementEditStates?.surname}
										multipleState={true}
										setValue={setUserManagementEditStates}
										labelColor='#292929'
										label={t('surname')}
										placeholder=''
										type='text'
									/>
								</div>
								<div className='userManagement-addingModal lastUpdateUserManagementModal'>
									<Input
										readOnly={editableData?.externalId ? true : false}
										name='userName'
										value={userManagementEditStates?.userName}
										multipleState={true}
										setValue={setUserManagementEditStates}
										labelColor='#292929'
										label={t('username')}
										placeholder=''
										type='text'
									/>
									{!editableData?.externalId && (
										<Input
											readOnly={editableData?.externalId ? true : false}
											name='email'
											value={userManagementEditStates?.email}
											multipleState={true}
											setValue={setUserManagementEditStates}
											labelColor='#292929'
											label={'Email'}
											placeholder=''
											type='text'
										/>
									)}
								</div>
								{!editableData?.externalId && (
									<div className='userManagement-addingModal lastUpdateUserManagementModal'>
										<Input
											name='password'
											value={userManagementEditStates?.password}
											multipleState={true}
											setValue={setUserManagementEditStates}
											labelColor='#292929'
											label={t('password')}
											placeholder=''
											type='text'
										/>
										<Input
											name='passwordConfirm'
											value={userManagementEditStates?.passwordConfirm}
											multipleState={true}
											setValue={setUserManagementEditStates}
											labelColor='#292929'
											label={t('confirmPassword')}
											placeholder=''
											type='text'
										/>
									</div>
								)}
								<div className='userManagement-addingModal lastUpdateUserManagementModal'>
									<SelectDr
										ref={selectUserRef1}
										onChange={(value) => setUserManagementEditStates((prev) => ({ ...prev, roles: value ? value : '' }))}
										defaultValue={editableData?.choiceRolesSelectList}
										type={1}
										isLoading={loading}
										options={editableData?.rolesSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
										placeholder={''}
										label={t('roles')}
									/>
									<SelectDr
										readOnly={editableData?.externalId ? true : false}
										ref={selectUserRef2}
										onChange={(value) => setUserManagementEditStates((prev) => ({ ...prev, companyId: value ? value : '' }))}
										defaultValue={editableData?.choiceCompanySelectList}
										type={0}
										isLoading={loading}
										options={editableData?.companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
										placeholder={''}
										label={t('companyName')}
									/>
								</div>
								<div  style={{marginTop: "20px"}} className='userManagement-addingModal lastUpdateUserManagementModal'>
									<SelectDr
										readOnly={editableData?.externalId ? true : false}
										//ref={selectUserRef2}
										onChange={(value) => setUserManagementEditStates((prev) => ({ ...prev, managerId: value ? value : '' }))}
										defaultValue={editableData?.choiceManagerSelectList}
										type={0}
										isLoading={loading}
										options={userSelectOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
										placeholder={''}
										label={t('thismanager')}
									/>
									<SelectDr
										//ref={selectUserRef1}
										windowParams = {true}
										key={userSubstituteSelectOPTIONS?.length}
										onChange={(value) => setUserManagementEditStates((prev) => ({ ...prev, substitutedUsers: value ? value : '' }))}
										defaultValue={
											userSubstituteSelectOPTIONS?.filter((subs) => editableData?.substitutedUsers?.includes(subs.value))
										}
										type={1}
										isLoading={loading}
										options={userSubstituteSelectOPTIONS}
										placeholder={''}
										label={t('proxyUser2')}
									/>
								</div>
								{/* <Input name="name" value={currencyEditStates?.name} multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('name')} placeholder='' type='text' />
							<Input readOnly name="code" value={currencyEditStates?.code}  multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('code')} placeholder='' type='text' />
							<Input readOnly style={{marginTop: "18px"}} name="symbol" value={currencyEditStates?.symbol}  multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('symbol')} placeholder='' type='text' />
							<Input readOnly={!currencyEditStates.isFixed && !currencyEditStates.isDefault ? true : false} style={{marginTop: "18px"}} name="rate" value={currencyEditStates?.rate}  multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('rate')} placeholder='' type='number' />
							 */}
								<div className='modal-type1-checkArea noneMargin'>
									<input
										onChange={(e) => setUserManagementEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
										checked={userManagementEditStates.status == 1 ? true : false}
										type='checkbox'
										name=''
										id=''
									/>
									<div>{t('active')}</div>
								</div>
								<div style={{marginTop: "8px"}} className='modal-type1-checkArea'>
									<input
										onChange={(e) => setUserManagementEditStates((prev) => ({ ...prev, isManager: e.target.checked}))}
										checked={userManagementEditStates.isManager}
										type='checkbox'
										name=''
										id=''
									/>
									<div>{t('manager')}</div>
								</div>
							</div>
								</div>
							) : (
								<div className='tab-modal-tab2'>
									<CheckboxTree
										nodes={editableData?.treeNodes}
										noCascade={true}
										checked={treeCheckedUpdateUserPage}
										expanded={treeExpandedUpdateUserPage}
										onCheck={(checked, targetNode) => {
											checked = checked.map(v => parseInt(v))
											if(targetNode?.checked && targetNode.isParent) {
												targetNode.children?.forEach(child => {
													if(!checked.includes(child.value)) {
														checked.push(child.value)
													}
												});

												if(!treeExpandedUpdateUserPage?.includes(targetNode.value)) {
													setTreeExpandedUpdateUserPage(prev => [...prev, targetNode.value])
												}
											}

											setTreeCheckedUpdateUserPage(checked)
										}}
										onExpand={(expanded) => {
											expanded = expanded.map(v => parseInt(v))
											setTreeExpandedUpdateUserPage(expanded)
										}}
										//iconsClass="fa5"
										icons={{
											check: <MdOutlineCheckBox size={24} />,
											uncheck: <MdOutlineCheckBoxOutlineBlank size={24} />,
											leaf: <RiCheckboxMultipleFill />,
											expandClose: <FaChevronRight />,
											expandOpen: <FaChevronDown />,
										}}
										//expandIconRenderer={renderExpandIcon}
									/>
								</div>
							)}
							<div className='tab-modal-modalGroup'>
								<Button
									onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
									name={t('cancel')}
									color='#fff'
									backgroundColor='#c1312f'
									height='48px'
								/>
								<Button onclick={editRow} name={t('update')} color='#fff' backgroundColor='#1392e5' height='48px' />
							</div>
						</div>
					) : 
					editableData.editItem == 'apiKey' ? (
						<div className='tab-modal'>
							<div className='tab-modal-lgTitle'>
								<Title name={t('updateApiKey')} color='#242424' fontweight='bold' size='24px' />
							</div>
							<div className='tab-modal-titles'>
								<div className={tabModal == 0 ? 'active' : ''} onClick={() => setTabModal(0)}>
								{t('apiKey')}
								</div>
								<div className={tabModal == 1 ? 'active' : ''} onClick={() => setTabModal(1)}>
								{t('companies')}
								</div>
							</div>
							{tabModal == 0 ? (
								<div className='tab-modal-tab1'>
									<div className='updContainerUserMng'>
								{/* <div className='userManagement-addingModal lastUpdateUserManagementModal'>
									<Input
										readOnly={editableData?.externalId ? true : false}
										name='name'
										value={userManagementEditStates?.name}
										multipleState={true}
										setValue={setUserManagementEditStates}
										labelColor='#292929'
										label={t('name')}
										placeholder=''
										type='text'
									/>
									<Input
										readOnly={editableData?.externalId ? true : false}
										name='surname'
										value={userManagementEditStates?.surname}
										multipleState={true}
										setValue={setUserManagementEditStates}
										labelColor='#292929'
										label={t('surname')}
										placeholder=''
										type='text'
									/>
								</div>
								<div className='userManagement-addingModal lastUpdateUserManagementModal'>
									<Input
										readOnly={editableData?.externalId ? true : false}
										name='userName'
										value={userManagementEditStates?.userName}
										multipleState={true}
										setValue={setUserManagementEditStates}
										labelColor='#292929'
										label={t('username')}
										placeholder=''
										type='text'
									/>
									{!editableData?.externalId && (
										<Input
											readOnly={editableData?.externalId ? true : false}
											name='email'
											value={userManagementEditStates?.email}
											multipleState={true}
											setValue={setUserManagementEditStates}
											labelColor='#292929'
											label={'Email'}
											placeholder=''
											type='text'
										/>
									)}
								</div>
								{!editableData?.externalId && (
									<div className='userManagement-addingModal lastUpdateUserManagementModal'>
										<Input
											name='password'
											value={userManagementEditStates?.password}
											multipleState={true}
											setValue={setUserManagementEditStates}
											labelColor='#292929'
											label={t('password')}
											placeholder=''
											type='text'
										/>
										<Input
											name='passwordConfirm'
											value={userManagementEditStates?.passwordConfirm}
											multipleState={true}
											setValue={setUserManagementEditStates}
											labelColor='#292929'
											label={t('confirmPassword')}
											placeholder=''
											type='text'
										/>
									</div>
								)} */}
									<div className='addItemsPage-container-date' style={{width: "100%", marginBottom: "16px"}}>
									<label style={{fontSize: "15px"}} htmlFor=''>{t('expirationDate')}</label>
									<DatePicker
										placeholderText={t('select')}
										//showTimeSelect
										dateFormat='dd/MM/yyyy'
										selected={apiKeyEditStates.expirationDate}
										onChange={(date) => setApiKeyEditStates(prev => ({...prev, expirationDate: date}))}
										showPopperArrow={false}
										locale={Locale()}
										isClearable
									/>
								</div>
								<div style={{height: "100%", maxWidth: "100%", marginBottom: "16px"}}>
										<label style={{fontSize: "15px"}}>{t('allowedIpAddresses')}</label>
										<TagsInput
											value={apiKeyEditStates.allowedIpAddresses || []}
											onChange={(ips) => setApiKeyEditStates(prev => ({...prev, allowedIpAddresses: ips}))}
											addKeys={[13, 9]}
											onlyUnique
											addOnBlur
										/>
									</div>
								<div className='userManagement-addingModal lastUpdateUserManagementModal'>						
									<SelectDr
										readOnly={true}
										ref={selectUserRef2}
										onChange={(value) => setApiKeyEditStates((prev) => ({ ...prev, companyId: value ? value : '' }))}
										defaultValue={editableData?.choiceCompanySelectList}
										type={0}
										isLoading={loading}
										options={editableData?.companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
										placeholder={''}
										label={t('companyName')}
									/>
								</div>
								{/* <div  style={{marginTop: "20px"}} className='userManagement-addingModal lastUpdateUserManagementModal'>
									<SelectDr
										readOnly={editableData?.externalId ? true : false}
										//ref={selectUserRef2}
										onChange={(value) => setUserManagementEditStates((prev) => ({ ...prev, managerId: value ? value : '' }))}
										defaultValue={editableData?.choiceManagerSelectList}
										type={0}
										isLoading={loading}
										options={userSelectOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value }))}
										placeholder={''}
										label={t('thismanager')}
									/>
									<SelectDr
										//ref={selectUserRef1}
										windowParams = {true}
										key={userSubstituteSelectOPTIONS?.length}
										onChange={(value) => setUserManagementEditStates((prev) => ({ ...prev, substitutedUsers: value ? value : '' }))}
										defaultValue={
											userSubstituteSelectOPTIONS?.filter((subs) => editableData?.substitutedUsers?.includes(subs.value))
										}
										type={1}
										isLoading={loading}
										options={userSubstituteSelectOPTIONS}
										placeholder={''}
										label={t('proxyUser2')}
									/>
								</div> */}
								{/* <Input name="name" value={currencyEditStates?.name} multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('name')} placeholder='' type='text' />
							<Input readOnly name="code" value={currencyEditStates?.code}  multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('code')} placeholder='' type='text' />
							<Input readOnly style={{marginTop: "18px"}} name="symbol" value={currencyEditStates?.symbol}  multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('symbol')} placeholder='' type='text' />
							<Input readOnly={!currencyEditStates.isFixed && !currencyEditStates.isDefault ? true : false} style={{marginTop: "18px"}} name="rate" value={currencyEditStates?.rate}  multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('rate')} placeholder='' type='number' />
							 */}
								<div className='modal-type1-checkArea noneMargin'>
									<input
										onChange={(e) => setApiKeyEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
										checked={apiKeyEditStates.status == 1 ? true : false}
										type='checkbox'
										name=''
										id=''
									/>
									<div>{t('active')}</div>
								</div>
								<div style={{marginTop: "8px"}} className='modal-type1-checkArea'>
									<input
										onChange={(e) => setApiKeyEditStates((prev) => ({ ...prev, generateNewKey: e.target.checked}))}
										checked={apiKeyEditStates.generateNewKey}
										type='checkbox'
										name=''
										id=''
									/>
									<div>{t('reCreateKey')}</div>
								</div>
							</div>
								</div>
							) : (
								<div className='tab-modal-tab2'>
									<CheckboxTree
										nodes={editableData?.treeNodes}
										noCascade={true}
										checked={treeCheckedUpdateUserPage}
										expanded={treeExpandedUpdateUserPage}
										onCheck={(checked, targetNode) => {
											checked = checked.map(v => parseInt(v))
											if(targetNode?.checked && targetNode.isParent) {
												targetNode.children?.forEach(child => {
													if(!checked.includes(child.value)) {
														checked.push(child.value)
													}
												});

												if(!treeExpandedUpdateUserPage?.includes(targetNode.value)) {
													setTreeExpandedUpdateUserPage(prev => [...prev, targetNode.value])
												}
											}

											setTreeCheckedUpdateUserPage(checked)
										}}
										onExpand={(expanded) => {
											expanded = expanded.map(v => parseInt(v))
											setTreeExpandedUpdateUserPage(expanded)
										}}
										//iconsClass="fa5"
										icons={{
											check: <MdOutlineCheckBox size={24} />,
											uncheck: <MdOutlineCheckBoxOutlineBlank size={24} />,
											leaf: <RiCheckboxMultipleFill />,
											expandClose: <FaChevronRight />,
											expandOpen: <FaChevronDown />,
										}}
										//expandIconRenderer={renderExpandIcon}
									/>
								</div>
							)}
							<div className='tab-modal-modalGroup'>
								<Button
									onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
									name={t('cancel')}
									color='#fff'
									backgroundColor='#c1312f'
									height='48px'
								/>
								<Button onclick={editRow} name={t('update')} color='#fff' backgroundColor='#1392e5' height='48px' />
								{/* <Button onclick={editRow} name={t('reCreateKey')} color='#fff' backgroundColor='#1392e5' height='48px' /> */}
							</div>
						</div>
					) : 
					editableData.editItem == 'mailTemplate' ? (
						<div className='tab-modal'>
							<div className='tab-modal-lgTitle'>
								<Title name={t('updateTemplate')} color='#242424' fontweight='bold' size='24px' />
							</div>
							<div className='tab-modal-titles'>
								<div className={tabModal2 == 0 ? 'active' : ''} onClick={() => setTabModal2(0)}>
								{t('default')}
								</div>
								<div className={tabModal2 == 1 ? 'active' : ''} onClick={() => setTabModal2(1)}>
								{t('english')}
								</div>
							</div>
							{tabModal2 == 0 ? (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
										<Input
											name={'subject'}
											multipleState={true}
											value={mailTemplateEditStates?.subject}
											setValue={setMailTemplateEditStates}
											labelColor='#292929'
											label={t('subject')}
											type='text'
										/>
										<div className='textAreaContainer'>
											<label htmlFor="areaBody">{t('content')}</label>
										    <textarea 
											   name="body" 
											   value={mailTemplateEditStates?.body} 
											   onChange={(e) => setMailTemplateEditStates(prev => ({...prev, body: e.target.value}))}
											   id="areaBody" 
											   cols="30" 
											   rows="10" 
											   placeholder=''
											></textarea>
										</div>
									</div>
									<div style={{marginTop: "20px"}} className='tab-modal-tab1-checkArea'>
										<input
											checked={mailTemplateEditStates.status == 0 ? 0 : 1}
											onChange={(e) => setMailTemplateEditStates(prev => ({...prev, status: e.target.checked ? 1 : 0}))}
											type='checkbox'
											name=''
											id=''
										/>
										<div>{t('active')}</div>
									</div>
								</div>
							) : (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
										<Input
											name={'tSubject'}
											multipleState={true}
											value={mailTemplateEditStates?.tSubject}
											setValue={setMailTemplateEditStates}
											labelColor='#292929'
											label={t('subject')}
											type='text'
										/>
										<div className='textAreaContainer'>
											<label htmlFor="areaBody">{t('content')}</label>
										    <textarea 
											   name="tBody" 
											   value={mailTemplateEditStates?.tBody} 
											   onChange={(e) => setMailTemplateEditStates(prev => ({...prev, tBody: e.target.value}))}
											   id="areaBody" 
											   cols="30" 
											   rows="10" 
											   placeholder=''
											></textarea>
										</div>
									</div>
									<div style={{marginTop: "20px"}} className='tab-modal-tab1-checkArea'>
										<input
											checked={mailTemplateEditStates.status == 0 ? 0 : 1}
											onChange={(e) => setMailTemplateEditStates(prev => ({...prev, status: e.target.checked ? 1 : 0}))}
											type='checkbox'
											name=''
											id=''
										/>
										<div>{t('active')}</div>
									</div>
								</div>
							)}
							<div className='tab-modal-modalGroup'>
								<Button
									onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
									name={t('cancel')}
									color='#fff'
									backgroundColor='#c1312f'
									height='48px'
								/>
								<Button onclick={editRow} name={t('update')} color='#fff' backgroundColor='#1392e5' height='48px' />
							</div>
						</div>
					) :
					editableData.editItem == 'gift-type' ? (
						<div className='tab-modal'>
							<div className='tab-modal-lgTitle'>
								<Title name={t('updateGiftType')} color='#242424' fontweight='bold' size='24px' />
							</div>
							<div className='tab-modal-titles'>
								<div className={tabModal2 == 0 ? 'active' : ''} onClick={() => setTabModal2(0)}>
								{t('default')}
								</div>
								<div className={tabModal2 == 1 ? 'active' : ''} onClick={() => setTabModal2(1)}>
								{t('english')}
								</div>
							</div>
							{tabModal2 == 0 ? (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
										<Input
											name='name'
											value={giftTypeEditStates?.name}
											multipleState={true}
											setValue={setGiftTypeEditStates}
											labelColor='#292929'
											label={t('giftType')}
											placeholder=''
											type='text'
										/>
										<Input
										    style = {{marginTop: "20px"}}
											name='description'
											value={giftTypeEditStates?.description}
											multipleState={true}
											setValue={setGiftTypeEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
										
									</div>
									<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftTypeEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, incomingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.incomingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('mandatoryIncomingGiftConfirmation')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, outgoingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.outgoingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftConsentMandatory')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, excludeFromIncomingLimit: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.excludeFromIncomingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('noIncomingGiftLimitCheck')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, excludeFromOutgoingLimit: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.excludeFromOutgoingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftNoLimitCheck')}</div>
										</div>
									{/* <div style={{marginTop: "20px"}} className='tab-modal-tab1-checkArea'>
										<input
											checked={mailTemplateEditStates.status == 0 ? 0 : 1}
											onChange={(e) => setMailTemplateEditStates(prev => ({...prev, status: e.target.checked ? 1 : 0}))}
											type='checkbox'
											name=''
											id=''
										/>
										<div>{t('active')}</div>
									</div> */}
								</div>
							) : (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
										<Input
											name='tName'
											value={giftTypeEditStates?.tName}
											multipleState={true}
											setValue={setGiftTypeEditStates}
											labelColor='#292929'
											label={t('giftType')}
											placeholder=''
											type='text'
										/>
										<Input
										    style = {{marginTop: "20px"}}
											name='tDescription'
											value={giftTypeEditStates?.tDescription}
											multipleState={true}
											setValue={setGiftTypeEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
										
									</div>
									<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftTypeEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, incomingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.incomingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('mandatoryIncomingGiftConfirmation')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, outgoingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.outgoingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftConsentMandatory')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, excludeFromIncomingLimit: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.excludeFromIncomingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('noIncomingGiftLimitCheck')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, excludeFromOutgoingLimit: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.excludeFromOutgoingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftNoLimitCheck')}</div>
										</div>
									{/* <div style={{marginTop: "20px"}} className='tab-modal-tab1-checkArea'>
										<input
											checked={mailTemplateEditStates.status == 0 ? 0 : 1}
											onChange={(e) => setMailTemplateEditStates(prev => ({...prev, status: e.target.checked ? 1 : 0}))}
											type='checkbox'
											name=''
											id=''
										/>
										<div>{t('active')}</div>
									</div> */}
								</div>
							)}
							<div className='tab-modal-modalGroup'>
								<Button
									onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
									name={t('cancel')}
									color='#fff'
									backgroundColor='#c1312f'
									height='48px'
								/>
								<Button onclick={editRow} name={t('update')} color='#fff' backgroundColor='#1392e5' height='48px' />
							</div>
						</div>
					) :
					editableData.editItem == 'gift-purpose' ? (
						<div className='tab-modal'>
							<div className='tab-modal-lgTitle'>
								<Title name={t('updateGiftIntent')} color='#242424' fontweight='bold' size='24px' />
							</div>
							<div className='tab-modal-titles'>
								<div className={tabModal2 == 0 ? 'active' : ''} onClick={() => setTabModal2(0)}>
								{t('default')}
								</div>
								<div className={tabModal2 == 1 ? 'active' : ''} onClick={() => setTabModal2(1)}>
								{t('english')}
								</div>
							</div>
							{tabModal2 == 0 ? (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
									<Input
											name='name'
											value={giftPurposeEditStates?.name}
											multipleState={true}
											setValue={setGiftPurposeEditStates}
											labelColor='#292929'
											label={t('PurposeoftheGift')}
											placeholder=''
											type='text'
										/>
										<Input
										    style={{marginTop: "20px"}}
											name='description'
											value={giftPurposeEditStates?.description}
											multipleState={true}
											setValue={setGiftPurposeEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
									</div>
									
									<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftPurposeEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftPurposeEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '8px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftPurposeEditStates((prev) => ({ ...prev, incomingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftPurposeEditStates.incomingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('mandatoryIncomingGiftConfirmation')}</div>
										</div>
										<div style={{ marginTop: '8px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftPurposeEditStates((prev) => ({ ...prev, outgoingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftPurposeEditStates.outgoingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftConsentMandatory')}</div>
										</div>
								</div>
							) : (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
									<Input
											name='tName'
											value={giftPurposeEditStates?.tName}
											multipleState={true}
											setValue={setGiftPurposeEditStates}
											labelColor='#292929'
											label={t('PurposeoftheGift')}
											placeholder=''
											type='text'
										/>
										<Input
										    style={{marginTop: "20px"}}
											name='tDescription'
											value={giftPurposeEditStates?.tDescription}
											multipleState={true}
											setValue={setGiftPurposeEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
									</div>
									
									<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftPurposeEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftPurposeEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '8px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftPurposeEditStates((prev) => ({ ...prev, incomingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftPurposeEditStates.incomingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('mandatoryIncomingGiftConfirmation')}</div>
										</div>
										<div style={{ marginTop: '8px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftPurposeEditStates((prev) => ({ ...prev, outgoingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftPurposeEditStates.outgoingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftConsentMandatory')}</div>
										</div>
								</div>
							)}
							<div className='tab-modal-modalGroup'>
								<Button
									onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
									name={t('cancel')}
									color='#fff'
									backgroundColor='#c1312f'
									height='48px'
								/>
								<Button onclick={editRow} name={t('update')} color='#fff' backgroundColor='#1392e5' height='48px' />
							</div>
						</div>
					) :
					editableData.editItem == 'related-partyType' ? (
						<div className='tab-modal'>
							<div className='tab-modal-lgTitle'>
								<Title name={t('updateRelatedPartyType')} color='#242424' fontweight='bold' size='24px' />
							</div>
							<div className='tab-modal-titles'>
								<div className={tabModal2 == 0 ? 'active' : ''} onClick={() => setTabModal2(0)}>
								{t('default')}
								</div>
								<div className={tabModal2 == 1 ? 'active' : ''} onClick={() => setTabModal2(1)}>
								{t('english')}
								</div>
							</div>
							{tabModal2 == 0 ? (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
									<Input
											name='name'
											value={giftRelatedPartyTypeEditStates?.name}
											multipleState={true}
											setValue={setGiftRelatedPartyTypeEditStates}
											labelColor='#292929'
											label={t('relatedPartyType')}
											placeholder=''
											type='text'
										/>
										<Input
										    style={{marginTop: "20px"}}
											name='description'
											value={giftRelatedPartyTypeEditStates?.description}
											multipleState={true}
											setValue={setGiftRelatedPartyTypeEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
									</div>
									
									<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftRelatedPartyTypeEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, incomingApprovalRequired: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.incomingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('mandatoryIncomingGiftConfirmation')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, outgoingApprovalRequired: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.outgoingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftConsentMandatory')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, excludeFromIncomingLimit: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.excludeFromIncomingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('noIncomingGiftLimitCheck')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, excludeFromOutgoingLimit: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.excludeFromOutgoingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftNoLimitCheck')}</div>
										</div>
								</div>
							) : (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
									<Input
											name='tName'
											value={giftRelatedPartyTypeEditStates?.tName}
											multipleState={true}
											setValue={setGiftRelatedPartyTypeEditStates}
											labelColor='#292929'
											label={t('relatedPartyType')}
											placeholder=''
											type='text'
										/>
										<Input
										    style={{marginTop: "20px"}}
											name='tDescription'
											value={giftRelatedPartyTypeEditStates?.tDescription}
											multipleState={true}
											setValue={setGiftRelatedPartyTypeEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
									</div>
									
									<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftRelatedPartyTypeEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, incomingApprovalRequired: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.incomingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('mandatoryIncomingGiftConfirmation')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, outgoingApprovalRequired: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.outgoingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftConsentMandatory')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, excludeFromIncomingLimit: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.excludeFromIncomingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('noIncomingGiftLimitCheck')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, excludeFromOutgoingLimit: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.excludeFromOutgoingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftNoLimitCheck')}</div>
										</div>
								</div>
							)}
							<div className='tab-modal-modalGroup'>
								<Button
									onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
									name={t('cancel')}
									color='#fff'
									backgroundColor='#c1312f'
									height='48px'
								/>
								<Button onclick={editRow} name={t('update')} color='#fff' backgroundColor='#1392e5' height='48px' />
							</div>
						</div>
					) :
					editableData.editItem == 'reasons-refusal' ? (
						<div className='tab-modal'>
							<div className='tab-modal-lgTitle'>
								<Title name={t('updateReasonsforRejection')} color='#242424' fontweight='bold' size='24px' />
							</div>
							<div className='tab-modal-titles'>
								<div className={tabModal2 == 0 ? 'active' : ''} onClick={() => setTabModal2(0)}>
								{t('default')}
								</div>
								<div className={tabModal2 == 1 ? 'active' : ''} onClick={() => setTabModal2(1)}>
								{t('english')}
								</div>
							</div>
							{tabModal2 == 0 ? (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
									<Input
											name='name'
											value={giftReasonForRefusalEditStates?.name}
											multipleState={true}
											setValue={setGiftReasonForRefusalEditStates}
											labelColor='#292929'
											label={t('reasonsforRefusal')}
											placeholder=''
											type='text'
										/>
										<Input
										    style = {{marginTop: "20px"}}
											name='description'
											value={giftReasonForRefusalEditStates?.description}
											multipleState={true}
											setValue={setGiftReasonForRefusalEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
									</div>
									
									<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftReasonForRefusalEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftReasonForRefusalEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
													
								</div>
							) : (
								<div className='tab-modal-tab1'>
									<div className='tab-modal-tab1-inputArea'>
									<Input
											name='tName'
											value={giftReasonForRefusalEditStates?.tName}
											multipleState={true}
											setValue={setGiftReasonForRefusalEditStates}
											labelColor='#292929'
											label={t('reasonsforRefusal')}
											placeholder=''
											type='text'
										/>
										<Input
										    style = {{marginTop: "20px"}}
											name='tDescription'
											value={giftReasonForRefusalEditStates?.tDescription}
											multipleState={true}
											setValue={setGiftReasonForRefusalEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
									</div>
									
									<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftReasonForRefusalEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftReasonForRefusalEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
													
								</div>
							)}
							<div className='tab-modal-modalGroup'>
								<Button
									onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
									name={t('cancel')}
									color='#fff'
									backgroundColor='#c1312f'
									height='48px'
								/>
								<Button onclick={editRow} name={t('update')} color='#fff' backgroundColor='#1392e5' height='48px' />
							</div>
						</div>
					) :
					(
						<div className='modal-type1 modal-type1-changed'>
							{/* {editableData?.editItem == 'gift-type' && <Title name={t('updateGiftType')} color='#242424' fontweight='bold' size='24px' />} */}
							{/* {editableData?.editItem == 'gift-purpose' && <Title name={t('updateGiftIntent')} color='#242424' fontweight='bold' size='24px' />} */}
							{/* {editableData?.editItem == 'related-partyType' && (
								<Title name={t('updateRelatedPartyType')} color='#242424' fontweight='bold' size='24px' />
							)} */}
							{/* {editableData?.editItem == 'reasons-refusal' && (
								<Title name={t('updateReasonsforRejection')} color='#242424' fontweight='bold' size='24px' />
							)} */}
							{editableData?.editItem == 'location-group' && <Title name={t('updateCompanies')} color='#242424' fontweight='bold' size='24px' />}
							{editableData?.editItem == 'currency' && <Title name={t('updateCurrency')} color='#242424' fontweight='bold' size='24px' />}
							{/* {editableData?.editItem == 'userManagement' && <Title name={t('updateUser')} color='#242424' fontweight='bold' size='24px' />} */}
							{editableData?.editItem == 'gift-limit' && <Title name={t('updateGiftLimit')} color='#242424' fontweight='bold' size='24px' />}

							<div className='location-group-addingModal'>
								{/* {editableData?.editItem == 'gift-type' && (
									<>
										<Input
											name='name'
											value={giftTypeEditStates?.name}
											multipleState={true}
											setValue={setGiftTypeEditStates}
											labelColor='#292929'
											label={t('giftType')}
											placeholder=''
											type='text'
										/>
										<Input
											name='description'
											value={giftTypeEditStates?.description}
											multipleState={true}
											setValue={setGiftTypeEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
										<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftTypeEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, incomingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.incomingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('mandatoryIncomingGiftConfirmation')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, outgoingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.outgoingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftConsentMandatory')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, excludeFromIncomingLimit: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.excludeFromIncomingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('noIncomingGiftLimitCheck')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftTypeEditStates((prev) => ({ ...prev, excludeFromOutgoingLimit: e.target.checked ? true : false }))}
												checked={giftTypeEditStates.excludeFromOutgoingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftNoLimitCheck')}</div>
										</div>
									</>
								)} */}
								{/* {editableData?.editItem == 'gift-purpose' && (
									<>
										<Input
											name='name'
											value={giftPurposeEditStates?.name}
											multipleState={true}
											setValue={setGiftPurposeEditStates}
											labelColor='#292929'
											label={t('PurposeoftheGift')}
											placeholder=''
											type='text'
										/>
										<Input
											name='description'
											value={giftPurposeEditStates?.description}
											multipleState={true}
											setValue={setGiftPurposeEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
										<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftPurposeEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftPurposeEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '8px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftPurposeEditStates((prev) => ({ ...prev, incomingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftPurposeEditStates.incomingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('mandatoryIncomingGiftConfirmation')}</div>
										</div>
										<div style={{ marginTop: '8px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftPurposeEditStates((prev) => ({ ...prev, outgoingApprovalRequired: e.target.checked ? true : false }))}
												checked={giftPurposeEditStates.outgoingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftConsentMandatory')}</div>
										</div>
									</>
								)} */}
								{/* {editableData?.editItem == 'related-partyType' && (
									<>
										<Input
											name='name'
											value={giftRelatedPartyTypeEditStates?.name}
											multipleState={true}
											setValue={setGiftRelatedPartyTypeEditStates}
											labelColor='#292929'
											label={t('relatedPartyType')}
											placeholder=''
											type='text'
										/>
										<Input
											name='description'
											value={giftRelatedPartyTypeEditStates?.description}
											multipleState={true}
											setValue={setGiftRelatedPartyTypeEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
										<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftRelatedPartyTypeEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, incomingApprovalRequired: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.incomingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('mandatoryIncomingGiftConfirmation')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, outgoingApprovalRequired: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.outgoingApprovalRequired ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftConsentMandatory')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, excludeFromIncomingLimit: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.excludeFromIncomingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('noIncomingGiftLimitCheck')}</div>
										</div>
										<div style={{ marginTop: '7px' }} className='modal-type1-checkArea'>
											<input
												onChange={(e) =>
													setGiftRelatedPartyTypeEditStates((prev) => ({ ...prev, excludeFromOutgoingLimit: e.target.checked ? true : false }))
												}
												checked={giftRelatedPartyTypeEditStates.excludeFromOutgoingLimit ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('outgoingGiftNoLimitCheck')}</div>
										</div>
									</>
								)} */}
								{/* {editableData?.editItem == 'reasons-refusal' && (
									<>
										<Input
											name='name'
											value={giftReasonForRefusalEditStates?.name}
											multipleState={true}
											setValue={setGiftReasonForRefusalEditStates}
											labelColor='#292929'
											label={t('reasonsforRefusal')}
											placeholder=''
											type='text'
										/>
										<Input
											name='description'
											value={giftReasonForRefusalEditStates?.description}
											multipleState={true}
											setValue={setGiftReasonForRefusalEditStates}
											labelColor='#292929'
											label={t('description')}
											placeholder=''
											type='text'
										/>
										<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftReasonForRefusalEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={giftReasonForRefusalEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
									</>
								)} */}
								{editableData?.editItem == 'location-group' && (
									<>
										<Input
											readOnly={editableData.isExternal ? true : false}
											name='code'
											value={locationGroupEditStates?.code}
											multipleState={true}
											setValue={setLocationGroupEditStates}
											labelColor='#292929'
											label={t('institutionCode')}
											placeholder=''
											type='text'
										/>
										<Input
											readOnly={editableData.isExternal ? true : false}
											name='name'
											value={locationGroupEditStates?.name}
											multipleState={true}
											setValue={setLocationGroupEditStates}
											labelColor='#292929'
											label={t('companyName')}
											placeholder=''
											type='text'
										/>
										<div className='location-group-addingModal-select' style={{ marginTop: '20px' }}>
											<SelectDr
												readOnly={editableData.isExternal ? true : false}
												ref={selectRef}
												onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, parentId: value ? value : '' }))}
												defaultValue={{ value: editableData?.parentId, label: editableData?.parentName }}
												type={0}
												isLoading={loading}
												options={getLocationGroupSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
												placeholder={''}
												label={t('topInstitution')}
											/>
										</div>

										{editableData.forCompanyId && <div className='location-group-addingModal-select' style={{ marginTop: '20px' }}>
											<SelectDr
												readOnly={true}
												ref={selectRef}
												onChange={(value) => setLocationGroupEditStates((prev) => ({ ...prev, forCompanyId: value ? value : '' }))}
												defaultValue={{ value: editableData?.forCompanyId, label: editableData?.forCompanyName }}
												type={0}
												isLoading={loading}
												options={getLocationGroupSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
												placeholder={''}
												label={t('forCompanyId')}
											/>
										</div>}

										{/* <div style={{marginTop: '20px'}}>
									<Input name="giftLimit" value={locationGroupEditStates?.giftLimit}  multipleState={true} setValue={setLocationGroupEditStates} labelColor='#292929' label={'Hediye Limiti'} placeholder='' type='text' />
									</div> */}
										<div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setLocationGroupEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={locationGroupEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{marginTop: "10px"}} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setLocationGroupEditStates((prev) => ({ ...prev, isManagerApprovalRequiredForHospitality: e.target.checked}))}
												checked={locationGroupEditStates.isManagerApprovalRequiredForHospitality}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('isManagerApprovalRequiredForHospitality')}</div>
										</div>

										
											{!locationGroupEditStates.forCompanyId?.value && <div style={{ marginTop: '10px' }} className='modal-type1-checkArea'>
												<input checked={locationGroupEditStates.canManagersApprove} onChange={(e) => setLocationGroupEditStates((prev) => ({ ...prev, canManagersApprove: e.target.checked}))} type='checkbox' name='' id='' />
												<div>{t('newPartiesApprovedByAdministrators')}</div>
											</div>}
								
										{ !locationGroupEditStates?.parentId?.value && 
										<>
										<div style={{marginTop: "10px"}} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setLocationGroupEditStates((prev) => ({ ...prev, includeSubCompanies: e.target.checked}))}
												checked={locationGroupEditStates.includeSubCompanies}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('includeSubCompanies')}</div>
										</div>
										<div style={{marginTop: "10px"}} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setLocationGroupEditStates((prev) => ({ ...prev, includeSubCompaniesToLimit: e.target.checked}))}
												checked={locationGroupEditStates.includeSubCompaniesToLimit}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('includeSubCompaniesToLimit')}</div>
										</div>
									   </>
										}
									</>
								)}
								{editableData?.editItem == 'currency' && (
									<>
										<Input
											name='name'
											value={currencyEditStates?.name}
											multipleState={true}
											setValue={setCurrencyEditStates}
											labelColor='#292929'
											label={t('name')}
											placeholder=''
											type='text'
										/>
										<Input
											readOnly
											name='code'
											value={currencyEditStates?.code}
											multipleState={true}
											setValue={setCurrencyEditStates}
											labelColor='#292929'
											label={t('code')}
											placeholder=''
											type='text'
										/>
										<Input
											readOnly
											style={{ marginTop: '18px' }}
											name='symbol'
											value={currencyEditStates?.symbol}
											multipleState={true}
											setValue={setCurrencyEditStates}
											labelColor='#292929'
											label={t('symbol')}
											placeholder=''
											type='text'
										/>
										<Input
											readOnly={currencyEditStates.isDefault ? true : false}
											style={{ marginTop: '18px' }}
											name='unit'
											value={currencyEditStates?.unit}
											multipleState={true}
											setValue={setCurrencyEditStates}
											labelColor='#292929'
											label={t('unit')}
											placeholder=''
											type='number'
										/>

										<div className='modal-type1-checkArea noneMargin'>
											<input
												disabled={currencyEditStates.isDefault ? true : false}
												onChange={(e) => setCurrencyEditStates((prev) => ({ ...prev, status: e.target.checked ? 1 : 0 }))}
												checked={currencyEditStates.status == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('active')}</div>
										</div>
										<div style={{ marginTop: '10px' }} className='modal-type1-checkArea'>
											<input disabled checked={currencyEditStates.isDefault == true ? true : false} type='checkbox' name='' id='' />
											<div>{t('default')}</div>
										</div>
										{/* <div style={{marginTop: "10px"}}  className='modal-type1-checkArea'>
							          <input onChange={(e) => setCurrencyEditStates(prev => ({...prev, isFixed: e.target.checked}))} checked={currencyEditStates.isFixed == true ? true : false} type='checkbox' name='' id='' />
							          <div>{t('fixed')}</div>
						            </div> */}
									</>
								)}

								{editableData?.editItem == 'gift-limit' && (
									<div className='updContainerUserMng'>
										<div className='userManagement-addingModal'>
											<SelectDr
												readOnly={true}
												//ref={selectUserRef1}
												onChange={(value) => setGiftLimitEditStates((prev) => ({ ...prev, companyId: value ? value : '' }))}
												defaultValue={editableData?.choiceCompanySelectList}
												type={0}
												//isLoading={loading}
												options={editableData?.companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
												placeholder={''}
												label={t('companyName')}
											/>
											<SelectDr
												readOnly={true}
												//ref={selectUserRef1}
												onChange={(value) => setGiftLimitEditStates((prev) => ({ ...prev, targetCompanyId: value ? value : '' }))}
												defaultValue={editableData?.choiceTargetCompanySelectList}
												type={0}
												//isLoading={loading}
												options={editableData?.companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
												placeholder={''}
												label={t('giftRelatedOrganizationName')}
											/>
										</div>
										<div className='userManagement-addingModal'>
											{/* <Input readOnly={editableData?.externalId ? true : false} name="userName" value={userManagementEditStates?.userName} multipleState={true} setValue={setUserManagementEditStates} labelColor='#292929' label={t('username')} placeholder='' type='text' />
									{!editableData?.externalId && <Input readOnly={editableData?.externalId ? true : false} name="email" value={userManagementEditStates?.email} multipleState={true} setValue={setUserManagementEditStates} labelColor='#292929' label={"Email"} placeholder='' type='text' />}                            */}

											<Input
												style={{ marginTop: '20px' }}
												name='incomingLimit'
												value={giftLimitEditStates?.incomingLimit}
												multipleState={true}
												setValue={setGiftLimitEditStates}
												labelColor='#292929'
												label={t('incomingLimit')}
												placeholder=''
												type='text'
											/>

											<Input
												style={{ marginTop: '20px' }}
												readOnly={true}
												name='remainingIncomingLimit'
												value={editableData?.remainingIncomingLimit}
												//multipleState={true}
												//setValue={setGiftLimitEditStates}
												labelColor='#292929'
												label={t('incomingLimitRemaining')}
												placeholder=''
												type='text'
											/>
										</div>

										<div className='userManagement-addingModal'>
											<Input
												name='outgoingLimit'
												value={giftLimitEditStates?.outgoingLimit}
												multipleState={true}
												setValue={setGiftLimitEditStates}
												labelColor='#292929'
												label={t('outgoingLimit')}
												placeholder=''
												type='text'
											/>
											<Input
												readOnly={true}
												name='remainingOutgoingLimit'
												value={editableData?.remainingOutgoingLimit}
												//multipleState={true}
												//setValue={setGiftLimitEditStates}
												labelColor='#292929'
												label={t('outgoingLimitRemaining')}
												placeholder=''
												type='text'
											/>
										</div>
										<div style={{ marginBottom: '0px' }} className='userManagement-addingModal'>
											<SelectDr
												readOnly={true}
												//ref={selectUserRef2}
												onChange={(value) => setGiftLimitEditStates((prev) => ({ ...prev, currencyId: value ? value : '' }))}
												defaultValue={editableData?.choiceCurrencySelectList}
												type={0}
												//isLoading={loading}
												options={editableData?.currencySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
												placeholder={''}
												label={t('CurrencyUnit')}
											/>
											
											<SelectDr
												readOnly={true}
												//ref={selectUserRef2}
												onChange={(value) => setGiftLimitEditStates((prev) => ({ ...prev, year: value ? value : '' }))}
												defaultValue={editableData?.choiceGiftLimitYearOptions}
												type={0}
												//isLoading={loading}
												options={editableData?.giftLimitYearOptions?.map((prev) => ({ value: prev.name, label: prev.value }))}
												placeholder={''}
												label={t('year')}
											/>
										</div>
										{/* <Input name="name" value={currencyEditStates?.name} multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('name')} placeholder='' type='text' />
									<Input readOnly name="code" value={currencyEditStates?.code}  multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('code')} placeholder='' type='text' />
									<Input readOnly style={{marginTop: "18px"}} name="symbol" value={currencyEditStates?.symbol}  multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('symbol')} placeholder='' type='text' />
									<Input readOnly={!currencyEditStates.isFixed && !currencyEditStates.isDefault ? true : false} style={{marginTop: "18px"}} name="rate" value={currencyEditStates?.rate}  multipleState={true} setValue={setCurrencyEditStates} labelColor='#292929' label={t('rate')} placeholder='' type='number' />
								     */}
										{/* <div className='modal-type1-checkArea noneMargin'>
											<input
												onChange={(e) => setGiftLimitEditStates((prev) => ({ ...prev, isGeneralManagerApprovalRequired: e.target.checked ? 1 : 0 }))}
												checked={giftLimitEditStates.isGeneralManagerApprovalRequired == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('generalManagerApproval')}</div>
										</div> */}
										<div style={{marginTop: "18px"}} className='modal-type1-checkArea'>
											<input
												onChange={(e) => setGiftLimitEditStates((prev) => ({ ...prev, isManagerApprovalRequiredForIncoming: e.target.checked ? 1 : 0 }))}
												checked={giftLimitEditStates.isManagerApprovalRequiredForIncoming == 1 ? true : false}
												type='checkbox'
												name=''
												id=''
											/>
											<div>{t('isManagerApprovalRequiredForIncoming')}</div>
										</div>
									</div>
								)}
							</div>
							<div className='modal-type1-buttonGroup'>
								<Button
									onclick={() => {
										dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
									}
									}
									name={t('cancel')}
									color='#fff'
									backgroundColor='#c1312f'
									height='48px'
								/>
								<Button onclick={editRow} name={t('update')} color='#fff' backgroundColor='#1392e5' height='48px' />
							</div>
						</div>
					)}
				</Modal>
			)}
			{
				modalStatus == "getApiKey" && (
					<Modal>
                        <Title name={t('apiKey')} color='#242424' fontweight='bold' size='24px' />
						<div style={{marginTop: "16px", marginBottom: "16px", fontSize: "16px"}}>
							{modalId}
						</div>
						<Button onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))} name={t('close')} color='#fff' backgroundColor='#1392e5' height='48px' />
					</Modal>
				)
			}
			{modalToggle && modalStatus == 'adminPanel-deleteModal' && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						{/* <Title name={t('updateGiftLimit')} color='#242424' fontweight='bold' size='24px' />
						<div className='location-group-addingModal'>
							<Input value={editableData?.name} labelColor='#292929' label={t('companyName')} placeholder='' type='text' />
							<Input value={editableData?.limit} labelColor='#292929' label='Limit' placeholder='' type='text' />
						</div> */}
						<Title name={t('areyousureyouwanttodeletetheselectedline')} color='#242424' size='20px' />

						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => {
									dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#1392e5'
								height='48px'
							/>
							<Button onclick={deleteRow} name={t('delete')} color='#fff' backgroundColor='#c1312f' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

Table.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	filterType: PropTypes.number,
	setSearch: PropTypes.func,
	handleFilterChange: PropTypes.func,
	paginationAllFunctionsList: PropTypes.object,
	sortTable: PropTypes.func,
	gridCSS: PropTypes.string,
	setFilters: PropTypes.func,
	isLoading: PropTypes.bool,
	filters: PropTypes.object,
}

export default Table
